import bookingRepository from './bookingRepository';

const bookingService = {};

bookingService.weeklyBooking = (studentId, tutorId, startTime, bookingCount) => {
  const timeStamps = [...Array(bookingCount)].map(
    (_, index) => startTime.getTime() + index * 7 * 24 * 60 * 60 * 1000,
  );
  return bookingRepository.multipleBookingByTimestamps(studentId, tutorId, timeStamps);
};

bookingService.massCancel = (studentUserId, startPeriodTimestamp, cancelNote) => bookingRepository.cancelAllBookingsAfterTimestamp(studentUserId, startPeriodTimestamp, cancelNote)

export default bookingService;
