import React, { useState } from 'react';
import {
  Button,
  Form,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Config from '../../Config/index';
import authProvider from '../../ReactAdmin/authProvider';

const RetractButton = ({ walletId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async ({ amount, note }) => {
    setLoading(false);
    axios
      .post(
        `${Config.apiUrl}/wallet/retract`,
        { walletId, amount, note },
        { headers: { Authorization: `Bearer ${authProvider.getAccessToken()}` } },
      )
      .then((response) => {
        notify('Successfully retracted');
        setShowDialog(false);
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        notify(error.response.data.errorMessage || error, { type: 'warning' });
        setShowDialog(false);
        setLoading(false);
      });
  };
  return (
    <>
      <Button onClick={handleClick} label="Retract" startIcon={<RemoveCircleIcon />} />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Retract">
        <DialogTitle>Retract</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput source="amount" validate={required()} fullWidth />
            <TextInput source="note" fullWidth />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
              <CancelIcon />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
export default RetractButton;
