import {
  AutocompleteInput,
  Create,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  Edit, useDataProvider,
} from 'react-admin';
import React, { useEffect, useState } from 'react';

export default () => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  useEffect(async () => {
    const { data } = await dataProvider.getList('Users', {
      pagination: { page: 1, perPage: 1000000 },
    });
    setUsers(data.filter(user => user.email).map(user => ({id: user.id, email: user.email})));
  }, []);
  return (
    <Edit>
      <SimpleForm>
        <AutocompleteInput source="userId" optionText="email" choices={users} isLoading={users === []} optionValue="id" sx={{ minWidth: 300 }}/>
        <ReferenceInput source="roleId" reference="roles">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
