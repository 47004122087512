import React, { useEffect, useState } from 'react';
import { Admin, Edit, EditGuesser, ListGuesser, Resource, ShowGuesser } from 'react-admin';
import { useApolloClient } from '@apollo/react-hooks';
import pgDataProvider from 'ra-postgraphile';
import SimpleRestProvider from 'ra-data-simple-rest';
import StudentList from '../Student/StudentList';
import authProvider from '../../ReactAdmin/authProvider';
import UserShow from '../User/UserShow';
import WalletList from '../Wallet/WalletList';
import WalletShow from '../Wallet/WalletShow';
import BookingViewList from '../BookingView/BookingViewList';
import Config from '../../Config';
import TutorList from '../Tutor/TutorList';
import TutorShow from '../Tutor/TutorShow';
import FeeTutorList from '../FeeTutors/FeeTutorList';
import FeeTutorEdit from '../FeeTutors/FeeTutorEdit';
import TransactionViewList from '../TransactionView/TransactionViewList';
import TransactionShow from '../Transaction/TransactionShow';
import TransactionEdit from '../Transaction/TransactionEdit';
import TransactionCreate from '../Transaction/TransactionCreate';
import ConnectorList from '../Connector/ConnectorList';
import ConnectorShow from '../Connector/ConnectorShow';
import WalletEdit from '../Wallet/WalletEdit';
import BookingEdit from '../Booking/BookingEdit';
import BookingShow from '../Booking/BookingShow';
import UserEdit from '../User/UserEdit';
import ConnectorEdit from '../Connector/ConnectorEdit';
import LedgerList from '../Ledger/LedgerList';
import StaffList from '../Staff/StaffList';
import TalentList from '../Talent/TalentList';
import StaffEdit from '../Staff/StaffEdit';
import StaffCreate from '../Staff/StaffCreate';
import UserPermissionList from '../UserPermission/UserPermissionList';
import UserPermissionEdit from '../UserPermission/UserPermissionEdit';
import UserPermissionCreate from '../UserPermission/UserPermissionCreate';
import TutorEdit from '../Tutor/TutorEdit';
import VoucherCreate from '../Voucher/VoucherCreate';
import VoucherList from '../Voucher/VoucherList';
import VoucherShow from '../Voucher/VoucherShow';
import UserRoleList from '../UserRole/UserRoleList';
import UserRoleCreate from '../UserRole/UserRoleCreate';
import UserRoleEdit from '../UserRole/UserRoleEdit';
import StaffShow from '../Staff/StaffShow';
import VoucherTypeEdit from '../VoucherTypes/VoucherTypeEdit';
import VoucherTypeCreate from '../VoucherTypes/VoucherTypeCreate';
import VoucherTypeList from '../VoucherTypes/VoucherTypeList';
import VoucherTypeShow from '../VoucherTypes/VoucherTypeShow';
import CourseList from '../Course/CourseList';
import RoleList from '../Role/RoleList';
import StudentCurriculumList from '../StudentCurriculum/StudentCurriculumList';
import LessonPlanList from '../LessonPlan/LessonPlanList';
import StudentCurriculumShow from '../StudentCurriculum/StudentCurriculumShow';
import TopicList from '../Topic/TopicList';
import TrialRequestViewList from '../TrialRequest/TrialRequestViewList';
import TrialRequestShow from '../TrialRequest/TrialRequestShow';
import ResourceStringList from '../ResourceString/ResourceStringList';
import ResourceStringShow from '../ResourceString/ResourceStringShow';
import ResourceStringEdit from '../ResourceString/ResourceStringEdit';
import LessonPlanShow from '../LessonPlan/LessonPlanShow';
import LessonPlanEdit from '../LessonPlan/LessonPlanEdit';
import StudentCurriculumEdit from '../StudentCurriculum/StudentCurriculumEdit';
import ResourceStringCreate from '../ResourceString/ResourceStringCreate';
import StageCreate from '../Stage/StageCreate';
import StageList from '../Stage/StageList';
import StageEdit from '../Stage/StageEdit';
import TalentsShow from '../Talent/TalentsShow';
import DepositRequestList from '../DepositRequest/DepositRequestList';
import DepositRequestShow from '../DepositRequest/DepositRequestShow';
import LearningContractList from '../LearingContracts/LearningContractList';
import LearningContractShow from '../LearingContracts/LearningContractShow';
import ContractPaymentList from '../ContractPayment/ContractPaymentList';
import ContractPaymentShow from '../ContractPayment/ContractPaymentShow';
import ContractPaymentEdit from '../ContractPayment/ContractPaymentEdit';
import ScheduleList from '../Schedule/ScheduleList';
import ScheduleShow from '../Schedule/ScheduleShow';
import SheduleEdit from '../Schedule/SheduleEdit';
import ScheduleDetailList from '../ScheduleDetail/ScheduleDetailList';
import ScheduleDetailShow from '../ScheduleDetail/ScheduleDetailShow';
import StudentSurveyShow from '../StudentSurvey/StudentSurveyShow';
import CourseShow from '../Course/CourseShow';
import CourseEdit from '../Course/CourseEdit';
import CourseCreate from '../Course/CourseCreate';
import TopicShow from '../Topic/TopicShow';
import TopicEdit from '../Topic/TopicEdit';
import TopicCreate from '../Topic/TopicCreate';
import LearningContractEdit from '../LearingContracts/LearningContractEdit';
import TutorStudentList from '../TutorStudents/TutorStudentList';
import TutorStudentEdit from '../TutorStudents/TutorStudentEdit';
import TutorStudentShow from '../TutorStudents/TutorStudentShow';

const can = (permissions, resource, action) =>
  permissions['*'] ||
  (permissions[resource] &&
    (permissions[resource].includes('*') || permissions[resource].includes(action)));

const guardResource = (permissions, resource, list, show, edit, create) =>
  (permissions['*'] || permissions[resource]) && (
    <Resource
      name={resource}
      list={can(permissions, resource, 'list') && list}
      edit={can(permissions, resource, 'edit') && edit}
      show={can(permissions, resource, 'show') && show}
      create={can(permissions, resource, 'create') && create}
    />
  );

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const client = useApolloClient();
  const auth = localStorage.getItem('auth');

  useEffect(() => {
    if (!auth) {
      setDataProvider(new SimpleRestProvider(Config.apiUrl));
      return;
    }
    (async () => {
      try {
        const dp = await pgDataProvider(client);
        setDataProvider(() => dp);
      } catch (e) {
        // await authProvider.logout();
        // window.location.replace('/login');

        // Dummy data provider to activate auth provider
        setDataProvider(new SimpleRestProvider(Config.apiUrl));
      }
    })();
  }, [auth]);

  return (
    dataProvider && (
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        {(permissions) =>
          permissions ? (
            <>
              {(permissions['*'] || permissions.students) && (
                <Resource
                  options={{ label: 'Students' }}
                  name="studentViews"
                  list={StudentList}
                  edit={EditGuesser}
                  show={ShowGuesser}
                />
              )}

              {(permissions['*'] || permissions.tutorStudents) && (
                <Resource
                  options={{ label: 'Tutor Students' }}
                  name="tutorStudents"
                  list={TutorStudentList}
                  edit={TutorStudentEdit}
                  show={TutorStudentShow}
                />
              )}
              {guardResource(
                permissions,
                'studentExperienceSurvey',
                null,
                StudentSurveyShow,
                null,
                null,
              )}

              {guardResource(permissions, 'trialRequests', TrialRequestViewList, TrialRequestShow)}
              {guardResource(
                permissions,
                'depositRequests',
                DepositRequestList,
                DepositRequestShow,
              )}
              {guardResource(
                permissions,
                'learningContracts',
                LearningContractList,
                LearningContractShow,
                LearningContractEdit,
              )}
              {guardResource(
                permissions,
                'contractPayments',
                ContractPaymentList,
                ContractPaymentShow,
                ContractPaymentEdit,
              )}

              {(permissions['*'] || permissions.users) && (
                <Resource name="users" edit={UserEdit} show={UserShow} />
              )}

              {permissions['*'] && (
                <Resource name="stages" create={StageCreate} list={StageList} edit={StageEdit} />
              )}

              {guardResource(permissions, 'wallets', WalletList, WalletShow, WalletEdit)}
              {(permissions['*'] || permissions.transactions) && (
                <Resource
                  name="transactions"
                  edit={TransactionEdit}
                  show={TransactionShow}
                  create={TransactionCreate}
                />
              )}

              {(permissions['*'] || permissions.bookings) && (
                <Resource name="bookings" edit={BookingEdit} show={BookingShow} />
              )}
              {(permissions['*'] || permissions.scheduleDetails) && (
                <Resource
                  name="scheduleDetails"
                  list={ScheduleDetailList}
                  show={ScheduleDetailShow}
                />
              )}
              {(permissions['*'] || permissions.schedules) && (
                <Resource
                  name="schedules"
                  list={ScheduleList}
                  edit={SheduleEdit}
                  show={ScheduleShow}
                />
              )}

              {(permissions['*'] || permissions.tutors) && (
                <Resource options={{ label: 'Tutors' }} name="tutorViews" list={TutorList} />
              )}

              {(permissions['*'] || permissions.tutors) && (
                <Resource name="tutors" edit={TutorEdit} show={TutorShow} />
              )}
              {(permissions['*'] || permissions.bookingViews) && (
                <Resource
                  name="bookingViews"
                  list={BookingViewList}
                  options={{ label: 'Bookings' }}
                />
              )}
              {guardResource(
                permissions,
                'courses',
                CourseList,
                CourseShow,
                CourseEdit,
                CourseCreate,
              )}
              {guardResource(permissions, 'topics', TopicList, TopicShow, TopicEdit, TopicCreate)}
              {guardResource(
                permissions,
                'studentCurriculums',
                StudentCurriculumList,
                StudentCurriculumShow,
                StudentCurriculumEdit,
              )}
              {guardResource(
                permissions,
                'lessonPlans',
                LessonPlanList,
                LessonPlanShow,
                LessonPlanEdit,
              )}

              {(permissions['*'] || permissions.transactions) && (
                <Resource
                  name="transactionViews"
                  list={TransactionViewList}
                  options={{ label: 'Transactions' }}
                />
              )}
              {(permissions['*'] || permissions.feeTutors) && (
                <Resource
                  name="feeTutors"
                  list={FeeTutorList}
                  edit={FeeTutorEdit}
                  show={ShowGuesser}
                />
              )}
              {(permissions['*'] || permissions.roles) && <Resource name="roles" list={RoleList} />}
              {(permissions['*'] || permissions.userRoles) && (
                <Resource
                  name="userRoles"
                  list={UserRoleList}
                  create={UserRoleCreate}
                  edit={UserRoleEdit}
                />
              )}
              {(permissions['*'] || permissions.connectors) && (
                <Resource
                  name="connectors"
                  list={ConnectorList}
                  show={ConnectorShow}
                  edit={ConnectorEdit}
                  options={{ label: 'Leads' }}
                />
              )}
              {(permissions['*'] || permissions.ledgers) && (
                <Resource name="ledgers" list={LedgerList} />
              )}
              {(permissions['*'] || permissions.enum_Bookings_statuses) && (
                <Resource name="enum_Bookings_statuses" />
              )}
              {(permissions['*'] || permissions.staffs) && (
                <Resource
                  name="staff"
                  list={StaffList}
                  edit={StaffEdit}
                  create={StaffCreate}
                  show={StaffShow}
                />
              )}
              {permissions['*'] && (
                <Resource
                  options={{ label: 'Resource String' }}
                  name="resourceString"
                  list={ResourceStringList}
                  show={ResourceStringShow}
                  edit={ResourceStringEdit}
                  create={ResourceStringCreate}
                />
              )}
              {(permissions['*'] || permissions.talents) && (
                <Resource
                  options={{ label: 'Talents' }}
                  name="talentStageViews"
                  list={TalentList}
                />
              )}
              {(permissions['*'] || permissions.bookings) && (
                <Resource name="talents" edit={EditGuesser} show={TalentsShow} />
              )}
              {(permissions['*'] || permissions.userPermissions) && (
                <Resource
                  name="userPermissions"
                  list={UserPermissionList}
                  edit={UserPermissionEdit}
                  create={UserPermissionCreate}
                />
              )}
              {guardResource(
                permissions,
                'voucherTypes',
                <VoucherTypeList />,
                <VoucherTypeShow />,
                <VoucherTypeEdit />,
                <VoucherTypeCreate />,
              )}
              {(permissions['*'] || permissions.vouchers) && (
                <Resource
                  name="vouchers"
                  list={VoucherList}
                  create={VoucherCreate}
                  show={VoucherShow}
                />
              )}
              <Resource options={{ label: 'Tutors' }} name="tutorViews" list={TutorList} />
            </>
          ) : (
            <></>
          )
        }
      </Admin>
    )
  );
};

export default App;
