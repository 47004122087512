import {
  AutocompleteInput,
  Button,
  DateTimeInput,
  NumberInput,
  RecordContextProvider,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import curriculumService from './Business/curriculumService';

const ApplyCurriculumButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async ({ courseId }) => {
    try {
      setLoading(true);
      await curriculumService.createStudentCurriculum(record.id, courseId);
      notify('Apply curriculum successfully', { type: 'success' });
      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const ApplyCurriculumToolbar = () => (
    <Toolbar>
      <SaveButton label="Apply" disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Apply Curriculum</Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Apply Curriculum">
        <DialogTitle>Apply Curriculum</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<ApplyCurriculumToolbar />}>
            <ReferenceInput
              source="courseId"
              reference="courses"
              filter={{ visible: true }}
              sort={{ field: 'displayOrder', order: 'DESC' }}
              perPage={5000}
            >
              <AutocompleteInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default ApplyCurriculumButton;
