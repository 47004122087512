import {
  AutocompleteInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
  useGetList, useDataProvider,
} from 'react-admin';
import Box from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';

export default (props) => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  useEffect(async () => {
    const { data } = await dataProvider.getList('Users', {
      pagination: { page: 1, perPage: 1000000 },
    });
    setUsers(data.filter(user => user.email).map(user => ({id: user.id, email: user.email})));
  }, []);

  return (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteInput source="userId" optionText="email" choices={users} isLoading={users === []} optionValue="id" sx={{ minWidth: 300 }}/>
      <TextInput source="callerNumber" />
      <TextInput source="name" />
      <TextInput source="zohoTag" />
      <DateInput source="startWorkingDay" />
      <DateInput source="endWorkingDay" />
      <TextInput source="zohoAccount" />
      <TextInput source="role" />
      <BooleanInput source="visible" />
    </SimpleForm>
  </Create>
)};
