import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

const StudentCurriculumListFilters = [

];

export default () => (
  <List>
    <Datagrid rowClick="edit">
      <ReferenceField source="userId" reference="users"><TextField source="name" /></ReferenceField>
      <ReferenceField source="courseId" reference="courses"><TextField source="name" /></ReferenceField>
      <TextField source="status" />
      <DateField source="startedAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
