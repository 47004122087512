import {
    Create,
    SimpleForm,
    TextInput
} from 'react-admin';
import Box from '@mui/material/Grid';
import React from 'react';
import { RichTextInput } from 'ra-input-rich-text';

export default (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
                <TextInput source="key" />
                <RichTextInput source="value"  fullWidth/>
                <TextInput source="language" />
            </Box>
        </SimpleForm>
    </Create>
);
