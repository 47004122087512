import React from 'react';
import {
  AutocompleteInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from 'react-admin';
import { Box } from '@mui/material';
import { LEARNING_CONTRACT_STAGES } from '../../ReactAdmin/constants';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <ReferenceInput
          filter={{ active: true }}
          source="saleBy"
          reference="Staff"
          alwaysOn
          perPage={10000}
          isRequired
        >
          <AutocompleteInput
            source="roleId"
            validate={required()}
            fullWidth
            multiline
            optionText="name"
            optionValue="id"
            inputProps={{ maxLength: 10240 }}
          />
        </ReferenceInput>
        <NumberInput source="totalFee" />
        <NumberInput source="totalLessons" />
        <NumberInput source="bonusLessons" />
        <NumberInput source="discount" />
        <SelectInput
          source="lastStage"
          choices={LEARNING_CONTRACT_STAGES}
          optionText="name"
          optionValue="id"
        />

        <SelectInput
          source="type"
          allowEmpty
          choices={[{ name: 'New' }, { name: 'Renew' }]}
          optionValue="name"
        />
      </Box>
    </SimpleForm>
  </Edit>
);
