import React from 'react';
import { Button, Confirm, DateField, ReferenceField, Show, SimpleShowLayout, TextField, TopToolbar, useCreate, useNotify, useRecordContext } from "react-admin";
import Config from "../../Config";
import authProvider from "../../ReactAdmin/authProvider";

const axios = require('axios');


const ApproveButton = ({ trialId, userId }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const notify = useNotify();


    const approve = () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        axios
            .post(
                `${Config.apiUrl}/student/approve-trial`,
                { trialId, userId ,approvalBy: auth.user.id },
                { headers: { Authorization: `Bearer ${auth.accessToken}` } },
            )
            .then((response) => {
                notify('Successfully approve trial');
            })
            .catch((error) => {
                notify(error.message, 'error');
            });
    };


    return (
        <>
            <Button
                color="primary"
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                Approve
            </Button>
            <Confirm
                isOpen={isOpen}
                title="Do you want to approve this trial request?"
                onConfirm={() => {
                    setIsOpen(false);
                    approve();
                }}
                onClose={() => {
                    setIsOpen(false);
                }}
                confirm="Yes"
                cancel="No"
            />
        </>
    );
};

const RejectButton = ({ trialId }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const notify = useNotify();


    const reject = () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        axios
            .post(
                `${Config.apiUrl}/student/reject-trial`,
                { trialId },
                { headers: { Authorization: `Bearer ${auth.accessToken}` } },
            )
            .then((response) => {
                notify('Successfully reject trial');

            })
            .catch((error) => {
                notify(error.message, 'error');
            });
    };

    return (
        <>
            <Button
                color="primary"
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                Reject
            </Button>
            <Confirm
                isOpen={isOpen}
                title="Do you want to reject this trial request?"
                onConfirm={() => {
                    reject();
                    setIsOpen(false);
                }}
                onClose={() => {
                    setIsOpen(false);
                }}
                confirm="Yes"
                cancel="No"
            />
        </>
    );
};


const TrialRequestShowActions = () => {
    const record = useRecordContext();
    const role = authProvider.getRole();
    return (
        <TopToolbar>
            {record && record.status === 'NEW' && (
                <>
                    <ApproveButton trialId={record.id} userId={record.userId} />
                    <RejectButton trialId={record.id} />
                </>
            )}
        </TopToolbar>
    );
};

export default (props) => (
    <Show  {...props} actions={<TrialRequestShowActions />}>
        <SimpleShowLayout>
            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="reason" />
            <TextField source="status" />
            <ReferenceField source="createdBy" reference="users" label="Created By" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="approvalBy" reference="users" label="Approval By" link="show">
                <TextField source="name" />
            </ReferenceField>

            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
