
import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    RichTextField,
    SelectInput,
    TextField,
} from 'react-admin';


export default (props) => (
    <List
        {...props}
        sort={{ field: 'updatedAt', order: 'DESC' }}
    >
        <Datagrid rowClick={(id, basePath, record) => `/resourceString/${record.id}/show`}>
            <TextField source="key" />
            <RichTextField source="value" />
            <TextField source="language" />

            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />

        </Datagrid>
    </List>
);
