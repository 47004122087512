import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  AutocompleteInput,
  TextField,
  useDataProvider,
} from 'react-admin';

export default () => {
  const dataProvider = useDataProvider();
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const { data: userPermissionsData } = await dataProvider.getList('UserPermissions', {
          pagination: { page: 1, perPage: 1000000 },
        });

        const userIds = userPermissionsData.map((userPermission) => userPermission.userId);
        const { data: usersData } = await dataProvider.getList('Users', {
          filter: { id: Array.from(new Set(userIds)) },
          pagination: { page: 1, perPage: 1000000 },
        });

        const options = usersData.map((user) => ({
          id: user.id,
          email: user.email,
        }));
        setUserOptions(options);
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    };

    fetchUserOptions();
  }, [dataProvider]);

  const UserPermissionFilter = [
    <AutocompleteInput
      source="userId"
      choices={userOptions}
      optionText="email"
      optionValue="id"
      fullWidth
      alwaysOn
      label="Email"
      style={{minWidth: '300px'}}
    />,
  ];
  return (
    <List filters={UserPermissionFilter}>
      <Datagrid rowClick="edit">
        <ReferenceField source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="resource" />
        <TextField source="action" />
      </Datagrid>
    </List>
  );
};
