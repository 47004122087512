import React from 'react';
import {
  BooleanField,
  ChipField,
  DateField,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  Toolbar,
  TopToolbar,
} from 'react-admin';
import authProvider from '../../ReactAdmin/authProvider';
import BookRecurringScheduleButton from '../User/BookRecurringScheduleButton';

const TutorAction = () => {
  const role = authProvider.getRole();

  return (
    <TopToolbar>
      <EditButton />
      {(!role || role === 'admin') && <BookRecurringScheduleButton isFromTutor />}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<TutorAction />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceManyField
        source="userId"
        reference="userRoles"
        target="userId"
        sort={{ field: 'roleId', order: 'ASC' }}
        label="Roles"
      >
        <SingleFieldList>
          <ReferenceField reference="roles" source="roleId">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="video" />
      <TextField source="youtubeVideoId" />
      <TextField source="bio" />
      <TextField source="languages" />
      <TextField source="specialties" />
      <TextField source="resume" />
      <TextField source="education" />
      <TextField source="experience" />
      <TextField source="accent" />
      <TextField source="targetStudent" />
      <TextField source="profession" />
      <TextField source="interests" />
      <BooleanField source="isActivated" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
