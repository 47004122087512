import { Box } from '@mui/material';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import AutocompleteDataInput from '../Custom/AutocompleteDataInput';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <AutocompleteDataInput
          source="tutorId"
          optionText={tutor=>`${tutor.email} - ${tutor.name}`}
          filterByField="name"
          perPage={10000000}
          resourceName="tutorViews"
          optionValue="userId"
        />

        <AutocompleteDataInput
          source="studentId"
          optionText={tutor=>`${tutor.email} - ${tutor.name}`}
          resourceName="users"
          filterByField="name"
          perPage={10000000}
        />

        <TextInput source="googleMeetingLink" />
      </Box>
    </SimpleForm>
  </Edit>
);
