import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="startPeriod" />
      <TextField source="endPeriod" />
      <TextField source="scheduleId" />
      <DateField source="startPeriodTimestamp" showTime />
      <DateField source="endPeriodTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
