import { DateField, Edit, ReferenceField, SelectInput, SimpleForm, TextField } from 'react-admin';
import React from 'react';

export default (props) => (
  <Edit>
    <SimpleForm>
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="courseId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
      <SelectInput
        source="status"
        choices={[
          { name: 'INIT' },
          { name: 'TEACHING' },
          { name: 'COMPLETED' },
          { name: 'STOPPED' },
        ]}
        optionValue="name"
      />
    </SimpleForm>
  </Edit>
);
