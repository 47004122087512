import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
    Button, RecordContextProvider, required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreate,
    useNotify,
    useRecordContext,
    useRefresh
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';
import { DialogActions } from '@mui/material';
import authProvider from '../../ReactAdmin/authProvider';

const CreateNoteButton = ({ userId,resourceName }) => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const notify = useNotify();
    const [create, { isLoading: isCreateLoading }] = useCreate('notes');


    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleSubmit = async (value) => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        try {
            setLoading(true);
            create(
                'notes',
                {
                    data: {
                        id: uuidv4(),
                        resourceId: record.id,
                        resourceName,
                        ownerId: auth.user.id,
                        note: value.note,
                    },
                },
                {
                    onSuccess: () => {
                        notify('Create note successfully.', 'success');
                        setShowDialog(false);
                        setLoading(false);
                        refresh();

                    },
                    onError: ({ err }) => {
                        notify(err.message, 'error');
                        setShowDialog(false);
                        setLoading(false);
                    },
                },
            );

            setShowDialog(false);
        } catch (e) {
            notify(e?.response?.data?.errorMessage, { type: 'warning' });
        } finally {
            setLoading(false);
        }
    };


    const SaveToolbar = () => (
        <Toolbar style={{justifyContent:'flex-end'}}>
            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                <CancelIcon />
            </Button>
            <SaveButton disabled={isLoading} />
        </Toolbar>
    );

    return (
        <>
            <Button onClick={handleClick}>Add Note</Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Note">
                <DialogTitle>Add Note</DialogTitle>
                <RecordContextProvider>
                    <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
                        <TextInput source="note" validate={required()} fullWidth multiline inputProps={{maxLength: 10240}} />
                    </SimpleForm>
                </RecordContextProvider>
            </Dialog>
        </>
    );
};

export default CreateNoteButton;
