import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';

export default () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField source="userId" reference="users"><TextField source="name" /></ReferenceField>
      <ReferenceField source="topicId" reference="topics"><TextField source="name" /></ReferenceField>
      <ReferenceField source="studentCurriculumId" reference="studentCurriculums"><TextField source="id" /></ReferenceField>
      <TextField source="type" />
      <TextField source="status" />
      <NumberField source="position" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
