import React, { useEffect, useState } from 'react';
import {
  Button,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useNotify,
  Labeled,
  Form,
  TextInput,
  SaveButton,
  required,
  useCreate,
  SelectField,
  RecordContextProvider,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  SelectInput,
  useCreatePath,
  useDataProvider,
  List,
} from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';

import CurrencyField from '../Custom/CurrencyField';
import LinkField from '../Custom/LinkField';
import Config from '../../Config/index';
import authProvider from '../../ReactAdmin/authProvider';
import ChangePasswordButton from './ChangePasswordButton';
import BookRecurringScheduleButton from './BookRecurringScheduleButton';
import MassCancelBookingButton from './MassCancelBookingButton';
import ApplyCurriculumButton from './ApplyCurriculumButton';

import ExistBooleanField from '../Custom/ExistBooleanField';
import CreateNoteButton from './CreateNoteButton';
import CreateLearningContractButton from './CreateLearningContractButton';
import StudentSurveyCreateButton from './StudentSurveyCreateButton';
import CreateUserRoleButton from './CreateUserRoleButton';
import ClonePermissionButton from './ClonePermissionButton';
import CreatePermissionButton from './CreatePermission';
import { LEARNING_CONTRACT_STAGES } from '../../ReactAdmin/constants';
import AutocompleteDataInput from '../Custom/AutocompleteDataInput';

const axios = require('axios');

const UserShowTitle = () => {
  const record = useRecordContext();
  return record ? <>{`User ${record.name}${record.phone ? ` (${record.phone})` : ''}`}</> : null;
};

function loginAs(userId) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/tutor/login-as-user-id`,
      { userId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {
      window
        .open(
          `${process.env.REACT_APP_APP_URL}/login-as?token=${response.data.refreshToken}`,
          '_blank',
        )
        .focus();
    })
    .catch((error) => {
      console.log(error);
    });
}

const addTrial = (userId, notify) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/student/add-trial`,
      { userId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {
      notify('Successfully add trial');
    })
    .catch((error) => {
      notify(error.message, 'error');
    });
};

const UpdateStageButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [create, { isLoading: isCreateLoading }] = useCreate('resourceStages');

  const updateStage = (value) => {
    setLoading(true);
    setShowDialog(false);
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    try {
      create(
        'resourceStages',
        {
          data: {
            id: uuidv4(),
            resourceName: 'Users',
            resourceId: record.id,
            stageId: value.resource,
            createdBy: userAuth.user.id,
          },
        },
        {
          onSuccess: () => {
            notify('Update stage successfully.', 'success');
            setShowDialog(false);
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
          },
        },
      );

      if (value.note) {
        const auth = JSON.parse(localStorage.getItem('auth'));
        create(
          'notes',
          {
            data: {
              id: uuidv4(),
              resourceId: record.id,
              resourceName: 'Users',
              ownerId: auth.user.id,
              note: value.note,
            },
          },
          {
            onSuccess: () => {},
            onError: ({ err }) => {
              notify(err.message, 'error');
            },
          },
        );
      }
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const role = authProvider.getRole();
  return (
    <>
      <Button color="primary" onClick={() => setShowDialog(true)}>
        Update Stage
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Update Stage">
        <DialogTitle>Update Stage</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={updateStage}
            toolbar={
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <CancelIcon />
                </Button>
                <SaveButton disabled={isLoading} />
              </Toolbar>
            }
          >
            <ReferenceInput source="resource" reference="stages" filter={{resource: ['Users', 'Students']}}>
              <SelectInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
            <TextInput source="note" fullWidth multiline inputProps={{ maxLength: 10240 }} />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const AddTutor = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [create, { isLoading: isCreateLoading }] = useCreate('tutorStudents');

  const addTutor = (value) => {
    setLoading(true);
    setShowDialog(false);
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    try {
      create(
        'tutorStudents',
        {
          data: {
            id: uuidv4(),
            studentId: record.id,
            tutorId: value.tutorId,
            googleMeetingLink: value.googleMeetingLink
          },
        },
        {
          onSuccess: () => {
            notify('Add tutor successfully.', 'success');
            setShowDialog(false);
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
          },
        },
      );

    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const role = authProvider.getRole();
  return (
    <>
      <Button color="primary" onClick={() => setShowDialog(true)}>
        Add tutor
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add tutor">
        <DialogTitle>Add tutor</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={addTutor}
            toolbar={
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <CancelIcon />
                </Button>
                <SaveButton disabled={isLoading} />
              </Toolbar>
            }
          >
            <AutocompleteDataInput
              source="tutorId"
              optionText={tutor=>`${tutor.email} - ${tutor.name}`}
              filterByField="name"
              perPage={10000000}
              resourceName="tutorViews"
              optionValue="userId"
            />

            <TextInput source="googleMeetingLink" text="Google Meeting Link" />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const RequestTrialButton = ({ userId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [create, { isLoading: isCreateLoading }] = useCreate('trialRequest');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (value) => {
    setLoading(true);
    const auth = JSON.parse(localStorage.getItem('auth'));

    create(
      'trialRequest',
      {
        data: {
          id: uuidv4(),
          userId,
          reason: value.reason,
          createdBy: auth.user.id,
          status: 'NEW',
        },
      },
      {
        onSuccess: () => {
          notify('Create trial request successfully.', 'success');
          setShowDialog(false);
          setLoading(false);
        },
        onError: ({ err }) => {
          notify(err.message, 'error');
          setShowDialog(false);
          setLoading(false);
        },
      },
    );
  };

  return (
    <>
      <Button color="primary" onClick={handleClick}>
        Request Trial
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Trial Request">
        <DialogTitle>Request Trial</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput source="reason" validate={required()} fullWidth multiline="true" />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
              <CancelIcon />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const UserShowActions = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const role = authProvider.getRole();
  return (
    <TopToolbar>
      {(!role || role === 'lettutor_cs') && <EditButton />}
      {record && <ChangePasswordButton userId={record.id} />}
      <Button color="primary" onClick={() => loginAs(record.id)}>
        Login As
      </Button>
      {(!role || role === 'lettutor_cs') && (
        <Button onClick={() => addTrial(record.id, notify)}>Add Trial</Button>
      )}
      {record && (
        <>
          <RequestTrialButton userId={record.id} />
        </>
      )}
    </TopToolbar>
  );
};

const RenderDepositRequest = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [walletId, setWalletId] = useState([]);

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const { data: wallet } = await dataProvider.getList('Wallets', {
          filter: { userId: record.id },
          pagination: { page: 1, perPage: 1000000 },
        });

        if (wallet && wallet.length) {
          setWalletId(wallet.map((res) => res.id));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchWallet();
  }, [dataProvider]);

  return walletId ? (
    <List resource="depositRequests" filter={{ walletId }} addLabel={false}>
      <Datagrid rowClick="show">
        <ReferenceField source="walletId" reference="wallets" label="User" link="show">
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="price" />
        <TextField source="venue" />
        <ReferenceField source="ledgerId" reference="ledgers">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="saleByStaffId" reference="staffs">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="saleAt" />
        <TextField source="status" />
        <TextField source="dealType" />
        <TextField source="note" />
        <TextField source="reason" />
        <ReferenceField source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  ) : (
    <></>
  );
};

export default (props) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  return (
    <Show title={<UserShowTitle />} actions={<UserShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Account">
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Name">
              <TextField label="name" source="name" />
            </Labeled>
            <Labeled label="Email">
              <EmailField source="email" />
            </Labeled>
            <Labeled label="Phone">
              <TextField source="phone" />
            </Labeled>
          </Box>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Referral">
              <TextField source="referral" />
            </Labeled>
            <Labeled label="Sale by">
              <ReferenceField source="saleByStaffId" reference="staffs">
                <TextField source="name" />
              </ReferenceField>
            </Labeled>
            <Labeled label="Cared by">
              <ReferenceField source="caredByStaffId" reference="staffs">
                <TextField source="name" />
              </ReferenceField>
            </Labeled>
          </Box>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <LinkField
              source="zaloUserId"
              target="_blank"
              prefix="https://oa.zalo.me/chatv2?oaid=4110034851965079772&uid="
              text="Zalo message"
            />
            <LinkField
              source="zohoUserId"
              target="_blank"
              prefix="https://crm.zoho.com/crm/lettutor/tab/Contacts/"
              text="Zoho contact"
            />
          </Box>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
            <Labeled label="Is activated">
              <BooleanField source="isActivated" />
            </Labeled>
            <Labeled label="Is phone activated">
              <BooleanField source="isPhoneActivated" />
            </Labeled>
            <Labeled label="Can send message">
              <BooleanField source="canSendMessage" />
            </Labeled>
            <Labeled label="Google Sign in">
              <ExistBooleanField source="google" label="Google" />
            </Labeled>
            <Labeled label="Facebook Sign in">
              <ExistBooleanField source="facebook" />
            </Labeled>
            <Labeled label="Apple sign in">
              <ExistBooleanField source="apple" />
            </Labeled>
          </Box>

          <ImageField source="avatar" sx={{ width: 100, height: 100 }} />
          <TextField source="country" />
          <TextField source="language" />
          <TextField source="birthday" />
          <BooleanField source="requestPassword" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <TextField source="deletedAt" />
          <TextField source="requireNote" />
          <TextField source="isTester" />
          <TextField source="level" />
          <NumberField source="timezone" />
        </Tab>
        <Tab label="Wallets">
          <ReferenceManyField reference="wallets" target="userId" addLabel={false}> 
            <Datagrid rowClick="show">
              <CurrencyField source="amount" textAlign="right" />
              <BooleanField source="isBlocked" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Deposit requests">
          <RenderDepositRequest />
        </Tab>
        <Tab label="Tutors">
          <Box>
            <AddTutor/>
          </Box>
          <ReferenceManyField
            reference="tutorStudents"
            target="studentId"
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <ReferenceField source="tutorId" reference="users" link="show">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField source="studentId" reference="users" link="show">
                <TextField source="name" />
              </ReferenceField>
              <LinkField source="googleMeetingLink" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Bookings">
          <Box>
            <BookRecurringScheduleButton />
            <MassCancelBookingButton />
          </Box>
          <ReferenceManyField
            reference="bookingViews"
            target="userId"
            addLabel={false}
            rowClick="show"
            pagination={<Pagination />}
            sort={{ field: 'startPeriodTimestamp', order: 'DESC' }}
          >
            <Datagrid
              rowClick={(id, basePath, bookingRecord) => `/bookings/${bookingRecord.id}/show`}
            >
              <DateField source="startPeriodTimestamp" showTime />
              <ReferenceField source="tutorId" reference="users" label="Tutor" link="show">
                <TextField source="name" />
              </ReferenceField>
              <ExistBooleanField source="isDeleted" showFalse={false} />
              <TextField source="studentRequest" />
              <ReferenceField
                source="lessonStatusId"
                reference="ClassReviewStatuses"
                label="Status"
              >
                <TextField source="status" />
              </ReferenceField>
              <LinkField
                source="studentMeetingLink"
                prefix={Config.appUrl}
                text="Student lesson link"
              />
              <LinkField
                source="tutorMeetingLink"
                prefix={Config.appUrl}
                text="Tutor lesson link"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Curriculums">
          <Box>
            <ApplyCurriculumButton />
          </Box>
          <ReferenceManyField reference="studentCurriculums" target="userId" addLabel={false}>
            <Datagrid rowClick="show">
              <ReferenceField source="userId" reference="users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField source="courseId" reference="courses">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="status" />
              <DateField source="startedAt" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Learning Contracts">
          <Box>
            <CreateLearningContractButton />
          </Box>
          <ReferenceManyField reference="learningContracts" target="userId">
            <Datagrid rowClick={(id, basePath) => `/learningContracts/${id}/show`}>
              <TextField source="id" />
              <ReferenceField source="userId" reference="users" label="Student" link="show">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField source="saleBy" reference="staff" label="Sale By" link="show">
                <TextField source="name" />
              </ReferenceField>
              <NumberField options={{ style: 'currency', currency: 'VND' }} source="totalFee" />
              <TextField source="totalLessons" />
              <TextField source="bonusLessons" />
              <NumberField options={{ style: 'currency', currency: 'VND' }} source="discount" />
              <SelectField
                source="lastStage"
                choices={LEARNING_CONTRACT_STAGES}
                optionText="name"
                optionValue="id"
              />
              <TextField source="type" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Notes">
          <Box>
            <CreateNoteButton userId={record?.id} resourceName="Users" />
          </Box>
          <ReferenceManyField
            reference="notes"
            target="resourceId"
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid>
              <TextField source="note" />
              <ReferenceField source="ownerId" reference="users" link="show" >
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Stage history">
          <Box>
            <UpdateStageButton />
          </Box>
          <ReferenceManyField
            reference="resourceStages"
            target="resourceId"
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid>
              <ReferenceField reference="stage" source="stageId" label="Stage">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference="users" source="createdBy" label="Created By" link="show">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Experience Survey">
          <Box>
            <StudentSurveyCreateButton />
          </Box>
          <ReferenceManyField
            reference="studentExperienceSurvey"
            target="studentId"
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <ReferenceField reference="tutors" source="tutorId">
                <ReferenceField reference="users" source="userId">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>

              <TextField source="learningStatus" />
              <ReferenceField reference="users" source="enteredBy">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField reference="saleBy" source="Staffs">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Roles">
          <Box>
            <CreateUserRoleButton />
          </Box>
          <ReferenceManyField
            reference="UserRoles"
            target="userId"
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid>
              <TextField source="id" />
              <ReferenceField reference="roles" source="roleId">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Permissions">
          <Box>
            <ClonePermissionButton />
            <CreatePermissionButton />
          </Box>
          <ReferenceManyField
            reference="UserPermission"
            target="userId"
            sort={{ field: 'resource', order: 'DESC' }}
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="resource" />
              <TextField source="action" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
