import { Create, SimpleForm, TextInput } from 'react-admin';

export default () => (
  <Create>
    <SimpleForm>
      <TextInput source="idPrefix" />
      <TextInput source="name" />
      <TextInput source="layout" />
      <TextInput source="image" fullWidth />
    </SimpleForm>
  </Create>
);
