import React, { useState } from 'react';
import {
  Button,
  required,
  SaveButton,
  TextInput,
  useNotify,
  SimpleForm,
  Toolbar,
  RecordContextProvider,
} from 'react-admin';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import authProvider from '../../ReactAdmin/authProvider';
import Config from '../../Config/index';
import { generatePassword } from '../../Utils/index';

const ChangePasswordButton = ({ userId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleSubmit = async ({ password }) => {
    setLoading(true);
    axios
      .post(
        `${Config.apiUrl}/user/change-password`,
        { userId, password },
        { headers: { Authorization: `Bearer ${authProvider.getAccessToken()}` } },
      )
      .then((response) => {
        notify('Password changed!');
        setShowDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        notify(error.response.data.errorMessage || error, { type: 'warning' });
        setShowDialog(false);
        setLoading(false);
      });
  };
  const ChangePasswordToolBar = (props) => (
    <Toolbar {...props}>
      <SaveButton alwaysEnable />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick} label="Change password" />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Change password">
        <DialogTitle>Change Password</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<ChangePasswordToolBar />}>
            <TextInput
              source="password"
              validate={required()}
              defaultValue={generatePassword(8)}
              fullWidth
            />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default ChangePasswordButton;
