import HttpClient from '../../../HttpClient/index';

const bookingRepository = {};

bookingRepository.multipleBookingByTimestamps = (studentUserId, tutorUserId, scheduleTimestamps) =>
  HttpClient.post('/booking/multiple-booking-by-timestamps', {
    studentUserId,
    tutorUserId,
    scheduleTimestamps,
  });

bookingRepository.cancelAllBookingsAfterTimestamp = (
  studentUserId,
  startPeriodTimestamp,
  cancelNote,
) => HttpClient.post('/booking/cancel-all-bookings-after-timestamp', {
    studentUserId,
    startPeriodTimestamp,
    cancelNote,
  });

export default bookingRepository;
