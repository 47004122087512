import React, { useState } from 'react';
import {
  Button,
  Form,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
  RecordContextProvider,
  useRefresh,
} from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Config from '../../Config/index';
import authProvider from '../../ReactAdmin/authProvider';

const TransferButton = ({ walletId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async ({ amount, note }) => {
    setLoading(true);
    axios
      .post(
        `${Config.apiUrl}/wallet/transfer`,
        { walletId, amount, note },
        { headers: { Authorization: `Bearer ${authProvider.getAccessToken()}` } },
      )
      .then((response) => {
        notify('Successfully transferred');
        setShowDialog(false);
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        notify(error.response.data.errorMessage || error, { type: 'warning' });
        setShowDialog(false);
        setLoading(false);
      });
  };
  return (
    <>
      <Button onClick={handleClick} label="Transfer" startIcon={<AddCircleIcon />} />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Transfer">
        <DialogTitle>Transfer</DialogTitle>
        <RecordContextProvider>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <TextInput source="amount" validate={required()} fullWidth />
              <TextInput source="note" fullWidth />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                <CancelIcon />
              </Button>
              <SaveButton />
            </DialogActions>
          </Form>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};
export default TransferButton;
