import React from 'react';
import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { Box } from '@mui/material';
import { STUDENT_LEVEL } from '../../ReactAdmin/constants';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <TextInput source="name" />
        <TextInput source="imageUrl" />
        <SelectInput source="level" choices={STUDENT_LEVEL} />
        <NumberInput source="defaultPrice" />
        <NumberInput source="coursePrice" />
        <TextInput source="reason" multiline minRows={3} />
        <TextInput source="purpose" multiline minRows={3} />
        <TextInput source="courseType" />
        <TextInput source="sectionType" />
        <NumberInput source="displayOrder" />
        <BooleanInput source="visible" />
      </Box>
    </SimpleForm>
  </Edit>
);
