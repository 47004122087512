import {
  Button,
  useRecordContext,
  SaveButton,
  RecordContextProvider,
  SimpleForm,
  AutocompleteInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  Toolbar,
  useNotify,
  required,
  useDataProvider,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import bookingService from './Business/bookingService';
import AutocompleteDataInput from '../Custom/AutocompleteDataInput';

const BookRecurringScheduleButton = ({ isFromTutor = false }) => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const [tutors, setTutors] = useState([]);
  useEffect(async () => {
    const { data } = await dataProvider.getList('tutorViews', {
      filter: { isActivated: true },
      pagination: { page: 1, perPage: 1000000 },
    });
    setTutors(data.map((tutor) => ({ userId: tutor.userId, name: tutor.name })));
  }, []);

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async ({ tutorId, startTime, bookingCount, userId }) => {
    try {
      setLoading(true);
      const tutor = isFromTutor ? record.userId : tutorId;
      const studentId = isFromTutor ? userId : record.id;
      await bookingService.weeklyBooking(
        studentId,
        tutor,
        startTime,
        isFromTutor ? 1 : bookingCount,
      );
      notify(isFromTutor ? 'Booking completed' : 'Weekly booking completed', { type: 'success' });
      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const RecurringBookingToolbar = () => (
    <Toolbar>
      <SaveButton label="Book" disabled={isLoading} />
    </Toolbar>
  );

  const validateRecurringBooking = ({ tutorId, userId, startTime, bookingCount }) => {
    const errors = {};
    if (!isFromTutor && !tutorId) errors.tutorId = 'No tutor selected';
    if (isFromTutor && !userId) errors.userId = 'No student selected';
    if (!isFromTutor && (!startTime || startTime < Date.now() + 2 * 60 * 60 * 1000))
      errors.startTime = 'Booking time must after 2 hours from now';
    if (!isFromTutor && (!Number.isInteger(bookingCount) || bookingCount < 1))
      errors.bookingCount = 'Number of booking must at least 1';
    return errors;
  };

  return (
    <>
      <Button onClick={handleClick}>{isFromTutor ? 'Book a class' : 'Book weekly schedule'}</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={isFromTutor ? 'Book a class' : 'Book weekly schedule'}
      >
        <DialogTitle>{isFromTutor ? 'Book a class' : 'Book weekly schedule'}</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={<RecurringBookingToolbar />}
            validate={validateRecurringBooking}
          >
            {!isFromTutor && (
               <AutocompleteDataInput
                source="tutorId"
                optionText={tutor=>`${tutor.email} - ${tutor.name}`}
                filterByField='email'
                resourceName="tutorViews"
                optionValue="userId"
                validate={required()}
                filter={{ isActivated: true }}
                fullWidth="true"

              />
            )}

            {isFromTutor && (
              <AutocompleteDataInput
                source="userId"
                optionText={tutor=>`${tutor.email} - ${tutor.name}`}
                filterByField='email'
                resourceName="users"
                validate={required()}
                filter={{ isActivated: true }}
                fullWidth="true"
              />
            )}

            <DateTimeInput source="startTime" minDateTime={new Date()} min={new Date()} fullWidth />
            {!isFromTutor && <NumberInput source="bookingCount" />}
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default BookRecurringScheduleButton;
