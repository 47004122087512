
import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    SelectInput,
    TextField,
} from 'react-admin';

const Filter = [
    <SelectInput
        alwaysOn
        source="status" choices={[
            { id: "NEW", name: "NEW" },
            { id: "APPROVE", name: "APPROVE" },
            { id: "REJECT", name: "REJECT" },
        ]}
    />,
];

export default (props) => (
    <List
        {...props}
        sort={{ field: 'updatedAt', order: 'DESC' }}
    >
        <Datagrid rowClick={(id, basePath, record) => `/trialRequests/${record.id}/show`}>
            <ReferenceField source="userId" reference="users" label="Student" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
            <TextField source="reason" />

            <ReferenceField source="createdBy" reference="users" label="Created By" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="approvalBy" reference="users" label="Approval By" link="show">
                <TextField source="name" />
            </ReferenceField>

            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />

        </Datagrid>
    </List>
);
