import { DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, NumberInput } from 'react-admin';
import React from 'react';

export default () => (
  <Edit>
    <SimpleForm>
      <SelectInput
        source="status"
        allowEmpty
        choices={[{ name: 'INIT' }, { name: 'IN_PROGRESS' }, { name: 'COMPLETED' }, { name: 'INTERRUPT' }]}
        optionValue="name"
      />
      <NumberInput source="position" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);
