import { AutocompleteInput, useChoices, useGetList } from 'react-admin';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AutocompleteDataInput = (props) => {
  const {
    source,
    optionText,
    optionValue,
    resourceName,
    filter,
    perPage = 50000,
    filterByField,
    fullWith = 'false',
    validate = [],
  } = props;
  const { textFilter, setTextFilter } = useState('');

  const { data = [], isLoading } = useGetList(resourceName, {
    sort: { field: filterByField, order: 'ASC' },
    filter: {
      [`${filterByField}`]: textFilter,
      ...filter,
    },
    pagination: { page: 1, perPage },
  });
  console.log(props);
  return (
    <AutocompleteInput
      source={source}
      fullWidth={fullWith}
      optionText={optionText}
      optionValue={optionValue}
      choices={data}
      isLoading={isLoading}
      debounce={500}
      onInputChange={(q) => {
        console.log(q);
        setTextFilter(q);
      }}
      validate={validate}
    />
  );
};

AutocompleteDataInput.propTypes = {
  source: PropTypes.string,
  optionText: PropTypes.any,
  optionValue: PropTypes.string,
  resourceName: PropTypes.string,
  filter: PropTypes.object,
  validate: PropTypes.any,
  filterByField: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  fullWith: PropTypes.bool,
};

AutocompleteDataInput.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
};

export default AutocompleteDataInput;
