import {
  Button,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useNotify,
  Labeled,
} from 'react-admin';
import React from 'react';
import CurrencyField from '../Custom/CurrencyField';

export default (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="courseId" reference="courses">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="startedAt" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Topics">
        <ReferenceManyField
          reference="lessonPlans"
          target="studentCurriculumId"
          addLabel={false}
          pagination={<Pagination />}
          sort={{ field: 'position', order: 'ASC' }}
        >
          <Datagrid rowClick="show">
            <ReferenceField source="topicId" reference="topics">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="type" />
            <TextField source="status" />
            <NumberField source="position" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
