import { Box } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { Edit, SimpleForm, TextInput } from "react-admin";


export default (props) => (
    <Edit {...props} redirect="show" >
        <SimpleForm redirect="show">
            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
                <TextInput source="key" />
                <RichTextInput source="value" fullWidth />
                <TextInput source="language" />
            </Box>
        </SimpleForm>
    </Edit>
);