import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';
import { CONTRACT_PAYMENT_STAGES } from '../../ReactAdmin/constants';

const Filter = [
  <SelectInput
    alwaysOn
    source="lastStage"
    choices={CONTRACT_PAYMENT_STAGES}
    optionText="name"
    optionValue="id"
  />,
];
export default (props) => (
  <List filters={Filter} sort={{ field: "updatedAt", order: "DESC" }} >
    <Datagrid rowClick={(id, basePath, record) => `/contractPayments/${record.id}/show`} >
      <ReferenceField source="learningContractId" reference="learningContracts" label="Student">
        <ReferenceField source="userId" reference="users" link='show' >
          <TextField source="name"  />
        </ReferenceField>
      </ReferenceField>
      <CurrencyField source="expectedAmount" />
      <CurrencyField source="actualAmount" />
      <NumberField source="totalLessons" />
      <DateField source="expectedTime" showTime />
      <DateField source="actualTime" showTime />

      <SelectField
        alwaysOn
        source="lastStage"
        choices={CONTRACT_PAYMENT_STAGES}
        optionText="name"
        optionValue="id"
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
