import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import LinkField from '../Custom/LinkField';

export default () => (
  <List sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="tutorId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="studentId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <LinkField source="googleMeetingLink" text="Google Meeting Link"/>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
