import { AutocompleteInput, Datagrid, DateField, List, SelectInput, TextField } from 'react-admin';

const StageList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={[
      <SelectInput
        alwaysOn
        source="resource"
        choices={[
          { id: 'Talent', name: 'Talents' },
          { id: 'Users', name: 'Users' },
          { id: 'Bookings', name: 'Bookings' },
          { id: 'Students', name: 'Students' },
          { id: 'ContractPayments', name: 'ContractPayments' },
          { id: 'LearningContracts', name: 'LearningContracts' },        
        ]}
      />,
    ]}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="resource" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default StageList;
