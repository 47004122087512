import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';

const userRoleFilter = [
  <ReferenceInput  source="userId" reference="Users" alwaysOn perPage={10000}>
    <AutocompleteInput
      style={{ minWidth: '300px' }}
      optionText="email"
      allowEmpty
      resettable
      fullWidth
      label='Email'
    />
  </ReferenceInput>,
  <ReferenceInput source="userId" reference="Users" alwaysOn perPage={10000}>
    <AutocompleteInput
      style={{ minWidth: '250px' }}
      optionText="name"
      allowEmpty
      resettable
      fullWidth
      label='Name'
    />
  </ReferenceInput>,
  <ReferenceInput source="roleId" reference="Roles" alwaysOn perPage={10000}>
    <AutocompleteInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
];
const UserRoleList = () => (
  <List sort={{ field: 'createdAt', order: 'DESC' }} filters={userRoleFilter}>
    <Datagrid rowClick="edit">
      <ReferenceField source="userId" reference="users" link='show' >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" link='show'>
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="roleId" reference="roles" link='show'>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default UserRoleList;
