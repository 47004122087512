import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';

export default (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Course detail">
        <SimpleShowLayout>
          <TextField source="id" />
          <div style={{ width: '200px', height: '150px' }}>
            <ImageField source="imageUrl" title="title" fullWidth label="Image" />
          </div>
          <TextField source="name" label="Name" />

          <TextField source="level" />
          <CurrencyField source="defaultPrice" />
          <CurrencyField source="coursePrice" />
          <TextField source="reason" />
          <TextField source="purpose" />
          <TextField source="courseType" />
          <TextField source="sectionType" />
          <TextField source="displayOrder" />
          <BooleanField source="visible" />

          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </SimpleShowLayout>
      </Tab>
      <Tab label="Topic">
        <ReferenceManyField
          reference="topics"
          target="courseId"
          addLabel={false}
          rowClick="show"
          pagination={<Pagination />}
          sort={{ field: 'updatedAt', order: 'DESC' }}
        >
          <Datagrid rowClick={(id, basePath, record) => `/topics/${record.id}/show`}>
            <TextField source="id" />
            <ReferenceField source="courseId" reference="courses">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="description" />
            <NumberField source="orderCourse" />
            <TextField source="videoUrl" />
            <TextField source="nameFile" />
            <TextField source="type" />
            <TextField source="numberOfPages" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
