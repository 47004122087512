import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

export default () => (
  <List sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <ReferenceField source="voucherTypeId" reference="voucherTypes">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="parentName" />
      <TextField source="studentName" />
      <TextField source="phone" />
      <DateField source="expirationDate"/>
      <TextField source="code" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
