import React, { useState } from 'react';
import {
  AutocompleteInput,
  Button,
  DateInput,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
} from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Grid';

const RequestDepositButton = ({ walletId, amount }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate('transactions');
  const [update, { updateLoading }] = useUpdate('wallets');
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return create(
      'depositRequests',
      {
        data: {
          id: uuidv4(),
          walletId,
          price: values.price,
          revenue: values.revenue,
          ledgerId: values.ledgerId || null,
          saleByStaffId: values.saleByStaffId || null,
          note: values.note,
          dealType: values.dealType,
          saleAt: values.saleAt,
          balance: parseInt(amount, 10) + parseInt(values.price, 10),
          reason: values.reason,
          status: 'NEW',
          createdBy: auth.user.id,
        },
      },
      {
        onSuccess: () => {
          notify('Deposit request successful', 'info');
          setShowDialog(false);
        },
        onError: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  return [
    <Button onClick={handleClick} label="Request Deposit" startIcon={<AddCircleIcon />} />,
    <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Request Deposit">
      <DialogTitle>Request Deposit</DialogTitle>

      <Form resource="posts" onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
            <NumberInput source="price" validate={required()} />
            <NumberInput source="revenue" defaultValue={0} />
            <ReferenceInput source="ledgerId" reference="ledgers">
              <SelectInput optionText="name" allowEmpty resettable />
            </ReferenceInput>
            <ReferenceInput source="saleByStaffId" reference="staffs" perPage={5000}>
              <AutocompleteInput optionText="name" allowEmpty resettable />
            </ReferenceInput>
            <DateInput source="saleAt" defaultValue={new Date().toISOString().split('T')[0]} />
            <SelectInput
              source="dealType"
              allowEmpty
              choices={[{ name: 'New' }, { name: 'Renew' }]}
              optionValue="name"
            />
          </Box>
          <TextInput source="note" fullWidth />
          <TextInput source="reason" validate={required()} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
            <CancelIcon />
          </Button>
          <SaveButton />
        </DialogActions>
      </Form>
    </Dialog>,
  ];
};
export default RequestDepositButton;
