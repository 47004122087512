import axios from 'axios';
import Config from '../Config/index';

const instance = axios.create({
  baseURL: Config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('auth'));
    if (token && token.accessToken) {
      const authConfig = {...config};
      authConfig.headers.Authorization = `Bearer ${token.accessToken}`;
      return authConfig;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
