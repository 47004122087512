import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Button,
  NumberInput,
  RecordContextProvider,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useCreate,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';

export default ({ userId }) => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('notes');

  const { data, loading, error } = useGetManyReference('stages', {
    id:'LearningContracts',
    target : 'resource',
    filter: {
      resource: 'LearningContracts',
      name: 'New'
    },
  });
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async (value) => {
    const contractId= uuidv4();
    const userAuth = JSON.parse(localStorage.getItem('auth'));

    try {
      setLoading(true);
      create(
        'LearningContracts',
        {
          data: {
            id: contractId,
            totalFee: value.totalFee,
            discount: value.discount,
            totalLessons: value.totalLessons,
            bonusLessons: value.bonusLessons,
            lastStage: "NEW",
            type: value.type,
            userId : record.id,
            saleBy: value.saleBy
          },
        },
        {
          onSuccess: () => {
            create(
              'resourceStages',
              {
                data: {
                  id: uuidv4(),
                  resourceName: 'LearningContracts',
                  resourceId: contractId,
                  stageId: data[0]?.id ,
                  createdBy: userAuth.user.id,
                },
              },
              {
                onSuccess: () => {
                  notify('Create Learning contract successfully.', 'success');
                  setShowDialog(false);
                  setLoading(false);
                  refresh();
                },
                onError: ({ err }) => {
                  notify(err.message, 'error');
                  setShowDialog(false);
                  setLoading(false);

                },
              },
            )          
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Add Learning Contract</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add Learning Contract"
      >
        <DialogTitle>Add Learning Contract</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="totalFee" validate={required()} fullWidth />
            <NumberInput source="discount" validate={required()} fullWidth />
            <NumberInput source="totalLessons" validate={required()} fullWidth />
            <NumberInput source="bonusLessons" validate={required()} fullWidth />
            <ReferenceInput filter={{active: true}} source="saleBy" reference="Staff" alwaysOn perPage={10000} isRequired>
              <AutocompleteInput
                source="roleId"
                validate={required()}
                fullWidth
                multiline
                optionText='name'
                optionValue='id'
                inputProps={{ maxLength: 10240 }}
              />
            </ReferenceInput>
            <SelectInput
              source="type"
              allowEmpty
              choices={[{ name: 'New' }, { name: 'Renew' }]}
              optionValue="name"
            />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};
