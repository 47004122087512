import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  RecordContextProvider,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';

import permissionService from './Business/permissionService';

const CreatePermissionButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [create, { isLoading: loading }] = useCreate('UserPermissions');

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      create(
        'UserPermissions',
        {
          data: {
            id: uuidv4(),
            userId: record.id,
            resource: value.resource,
            action: value.action,
          },
        },
        {
          onSuccess: () => {
            notify(`Create permisson  successfully !`, 'success');
            setShowDialog(false);
            setLoading(false);
            refresh();
          },
          onError: (e) => {
            notify(e?.response?.data?.errorMessage, { type: 'warning' });
            setShowDialog(false);
          },
        },
      );
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
      setShowDialog(false);
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar style={{ justifyContent: 'flex-end' }}>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Create Permission</Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create Permission">
        <DialogTitle>Create Permission</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <TextInput source="resource" fullWidth />
            <TextInput source="action" fullWidth />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default CreatePermissionButton;
