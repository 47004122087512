import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

export default () => (
  <List sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="layout" />
      <TextField source="image" />
      <TextField source="idPrefix" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
