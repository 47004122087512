import HttpClient from '../../../HttpClient/index';

// eslint-disable-next-line import/prefer-default-export
const createStudentCurriculum = (userId, courseId) =>
  HttpClient.post('/curriculum/create-student-curriculum', {
    userId,
    courseId,
  });

export default { createStudentCurriculum };
