import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  List,
  ReferenceField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  TextInput,
} from 'react-admin';

const tutorListFilters = [
  <TextInput name="name" alwaysOn label="Name" />,
  <BooleanInput source="isActivated" alwaysOn />,
];

export default (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={tutorListFilters}>
    <Datagrid rowClick={(id, basePath, record) => `/tutors/${record.id}/show`}>
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Avatar" source="userId" reference="users" link="show">
        <ImageField
          source="avatar"
          sx={{
            '& img': {
              width: '40px !important',
              height: '40px !important',
              objectFit: 'contain',
            },
          }}
        />
      </ReferenceField>
      <TextField source="languages" />
      <TextField source="education" />
      <TextField source="targetStudent" />
      <BooleanField source="isActivated" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
