import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
  SimpleShowLayout,
  Show,
  AutocompleteInput,
  Button,
  ReferenceField,
  BooleanField,
  useRecordContext,
  TopToolbar,
  EditButton,
  NumberField,
  Labeled,
} from 'react-admin';
import React from 'react';
import axios from 'axios';
import Box from '@mui/material/Grid';
import Config from '../../Config/index';
import authProvider from '../../ReactAdmin/authProvider';

function loginAsAdmin(userId) {
  axios
    .post(
      `${Config.apiUrl}/tutor/login-as-admin`,
      { userId },
      { headers: { Authorization: `Bearer ${authProvider.getAccessToken()}` } },
    )
    .then((response) => {
      localStorage.setItem('auth', JSON.stringify(response.data));
      window.location.replace('/');
    })
    .catch((error) => {
      console.log(error);
    });
}

const StaffShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <EditButton />
      {record && <Button onClick={() => loginAsAdmin(record.userId)} label="Login as Admin" />}
    </TopToolbar>
  );
};

export default (props) => (
  <Show actions={<StaffShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" label="Email">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="callerNumber" />
      <TextField source="name" />
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(4, 1fr)', width: 1 }}>
        <Labeled label="kpiCall">
          <NumberField source="kpiCall" />
        </Labeled>
        <Labeled label="kpiTrial">
          <NumberField source="kpiTrial" />
        </Labeled>
        <Labeled label="kpiNcc">
          <NumberField source="kpiNcc" />
        </Labeled>
        <Labeled label="kpiStudents">
          <NumberField source="kpiStudents" />
        </Labeled>
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <Labeled label="Zoho account">
          <TextField source="zohoAccount" />
        </Labeled>
        <Labeled label="Zoho Tag Id">
          <TextField source="zohoTagId" />
        </Labeled>
      </Box>
      <TextField source="startWorkingDay" />
      <TextField source="endWorkingDay" />
      <TextField source="role" />
      <BooleanField source="visible" />
    </SimpleShowLayout>
  </Show>
);
