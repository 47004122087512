import React from 'react';
import { DateField, RichTextField, Show, SimpleShowLayout, TextField, TopToolbar, useCreate, useNotify, useRecordContext } from "react-admin";

const axios = require('axios');

export default (props) => (
    <Show  {...props} >
        <SimpleShowLayout>

            <TextField source="key" />
            <RichTextField source="value" />

            <TextField source="language" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
