import { Box } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <ReferenceInput
          source="courseId"
          reference="courses"
          perPage={500000}
          alwaysOn
          sort={{ field: 'id', order: 'DESC' }}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="orderCourse" />
        <TextInput source="videoUrl" />
        <TextInput source="nameFile" />
        <TextInput source="type" />
        <RichTextInput source="beforeTheClassNotes" />
        <RichTextInput source="afterTheClassNotes" />
        <NumberInput source="numberOfPages" />
      </Box>
    </SimpleForm>
  </Edit>
);
