import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import LinkField from '../Custom/LinkField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="tutorId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="studentId" reference="users" link="show">
        <TextField source="name" />
        <div>
          <TextField source="email" />
        </div>
      </ReferenceField>
      <LinkField source="googleMeetingLink" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
