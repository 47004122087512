import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';

export default (props) => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="startPeriod" />
      <TextField source="endPeriod" />
      <TextField source="scheduleId" />
      <DateField source="startPeriodTimestamp" showTime />
      <DateField source="endPeriodTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
