import React from 'react';
import {
  BooleanInput,
  AutocompleteInput,
  Edit,
  ImageField,
  NumberInput,
  ReferenceInput,
  regex,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  TopToolbar,
  EditButton,
} from 'react-admin';
import Box from '@mui/material/Grid';

import { emptyToNull } from '../../ReactAdmin/utils/transformInput';
import authProvider from '../../ReactAdmin/authProvider';
import ChangePasswordButton from './ChangePasswordButton';

const transformRecord = (record) => ({
  ...record,
  email: (record.email && record.email.trim()) || null,
  caredByStaffId: record.caredByStaffId || null,
  saleByStaffId: record.saleByStaffId || null,
  phoneAuth: record.phoneAuth || null,
});

export default (props) => (
  <Edit {...props} redirect="show" transform={transformRecord}>
    <SimpleForm redirect="show">
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput source="name" />
        <TextInput source="email" />
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput
          source="phone"
          validate={regex(/^84\d{9}$/, 'Phone number must begin with 84')}
        />
        <TextInput source="phoneAuth" emptyValue={null} />
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput label="Zalo User Id" source="zaloUserId" />
        <TextInput label="Zoho User Id" source="zohoUserId" />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
        <BooleanInput source="isPhoneAuthActivated" />
        <BooleanInput source="isPhoneActivated" />
        <BooleanInput source="isActivated" />
        <BooleanInput source="canSendMessage" />
        <BooleanInput source="requestPassword" />
        <BooleanInput source="isTester" />
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', width: 1 }}>
        <SelectInput
          allowEmpty
          source="referral"
          choices={[
            { name: 'Customer Referral' },
            { name: 'Internal Referral' },
            { name: 'Self Referral' },
            { name: 'Self Social' },
            { name: 'Cold call' },
            { name: 'Facebook Ads' },
            { name: 'Google Ads' },
            { name: 'Tiktok Ads' },
            { name: 'Employee' },
            { name: 'Internal' },
            { name: 'Business Partner' },
            { name: 'Direct' },
          ]}
          optionValue="name"
        />
        <ReferenceInput
          reference="staffs"
          source="saleByStaffId"
          filter={{ active: true }}
          perPage={10000}
          format={emptyToNull}
          parse={emptyToNull}
        >
          <AutocompleteInput
            optionText="name"
            allowEmpty
            resettable
            format={emptyToNull}
            parse={emptyToNull}
          />
        </ReferenceInput>
        <ReferenceInput
          reference="staffs"
          source="caredByStaffId"
          perPage={10000}
          filter={{ active: true }}
          format={emptyToNull}
          parse={emptyToNull}
        >
          <AutocompleteInput
            optionText="name"
            allowEmpty
            resettable
            format={emptyToNull}
            parse={emptyToNull}
          />
        </ReferenceInput>
      </Box>
      <TextInput source="requireNote" fullWidth />
      <TextInput source="studySchedule" fullWidth />
    </SimpleForm>
  </Edit>
);
