import React, { useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  useCreate,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { Dialog, DialogTitle } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';
import authProvider from '../../ReactAdmin/authProvider';
import Config from '../../Config';
import CurrencyField from '../Custom/CurrencyField';
import { checkActionPermission } from '../../ReactAdmin/utils/util';
import { CONTRACT_PAYMENT_STAGES, CPStageEnum } from '../../ReactAdmin/constants';

const axios = require('axios');

const ApproveButton = ({ paymentId }) => {
  const record = useRecordContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const notify = useNotify();

  const approve = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!record.actualAmount) {
      notify('Actual amount must be greater than 0!', 'warning')
      return};
    axios
      .post(
        `${Config.apiUrl}/student/approve-payment`,
        { paymentId },
        { headers: { Authorization: `Bearer ${auth.accessToken}` } },
      )
      .then((response) => {
        notify('Successfully approve contract payment');
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Approve
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to approve this Contract Payment?"
        onConfirm={() => {
          setIsOpen(false);
          approve();
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const PaidButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [create, { isLoading: isCreateLoading }] = useCreate();
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const [update, { updateLoading }] = useUpdate();
  const { data, loading, er } = useGetManyReference('stages', {
    id: 'ContractPayments',
    target: 'resource',
    filter: {
      resource: 'ContractPayments',
    },
  });

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = (value) => {
    const date = new Date();
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    const state = record.expectedAmount <= value.actualAmount ? 'Paid' : 'Opened';
    update(
      'contractPayments',
      {
        id: record.id,
        data: {
          actualAmount: value.actualAmount,
          lastStage: record.lastStage !== 'Approved' ? state : 'Approved',
          actualTime: date.toLocaleString(),
          updatedAt: date.toLocaleString(),
        },
      },
      {
        onSuccess: () => {
          if (record.expectedAmount <= value.actualAmount) {
            if (record.lastStage === 'Approved') {
              axios
                .post(
                  `${Config.apiUrl}/student/update-contract`,
                  { paymentId: record.id },
                  { headers: { Authorization: `Bearer ${userAuth.accessToken}` } },
                )
                .then((response) => {})
                .catch((error) => {
                  notify(error.message, 'error');
                });
            }

            create(
              'resourceStages',
              {
                data: {
                  id: uuidv4(),
                  resourceName: 'ContractPayments',
                  resourceId: record.id,
                  stageId: data.find((val) => val.name === 'Paid')?.id,
                  createdBy: userAuth.user.id,
                },
              },
              {
                onSuccess: () => {
                  notify('Create contract payment successfully.', 'success');
                  setShowDialog(false);
                  refresh();
                },
                onError: ({ err }) => {
                  notify(err.message, 'error');
                  setShowDialog(false);
                },
              },
            );
          }

          notify('Update payment successfully', 'info');
          setShowDialog(false);
        },
        onError: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={updateLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={updateLoading} />
    </Toolbar>
  );

  return (
    <>
      {record.actualAmount < record.expectedAmount && (
        <Button
          onClick={() => {
            setShowDialog(true);
          }}
        >
          PAID
        </Button>
      )}
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Update Payment">
        <DialogTitle>Update Actual Amount</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="actualAmount" validate={required()} fullWidth />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const PaymentActions = () => {
  const record = useRecordContext();
  const permissions = authProvider.getPermissionsValue();
  return (
    <TopToolbar>
      {record &&
        checkActionPermission(permissions, 'contractPayments', 'approve') &&
        record.lastStage !== CPStageEnum.APPROVED && <ApproveButton paymentId={record.id} />}
      {record && checkActionPermission(permissions, 'contractPayments', 'paid') && <PaidButton />}
      {record && checkActionPermission(permissions, 'contractPayments', 'edit') && <EditButton />}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<PaymentActions />}>
    <TabbedShowLayout>
      <Tab label="Contract Payment">
        <SimpleShowLayout>
          <ReferenceField source="learningContractId" reference="learningContracts" label="Student">
            <ReferenceField source="userId" reference="users" link='show'>
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField
            source="learningContractId"
            reference="learningContracts"
            label="Contract ID"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <CurrencyField source="expectedAmount" />
          <CurrencyField source="actualAmount" />
          <NumberField source="totalLessons" />
          <ReferenceField source="ledgerId" reference="ledgers">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="expectedTime" showTime />
          <DateField source="actualTime" showTime />
          <SelectField
            alwaysOn
            source="lastStage"
            choices={CONTRACT_PAYMENT_STAGES}
            optionText="name"
            optionValue="id"
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
      </Tab>
      <Tab label="Stage history">
        <ReferenceManyField
          reference="resourceStages"
          target="resourceId"
          sort={{ field: 'updatedAt', order: 'DESC' }}
        >
          <Datagrid rowClick="show">
            <ReferenceField reference="stage" source="stageId" label="Stage">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference="users" source="createdBy" label="Created By">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
