import { v4 as uuidv4 } from 'uuid';
import {
  TextField,
  NumberField,
  required,
  ReferenceField,  Show,
  SimpleShowLayout,
} from 'react-admin';
import { useParams } from 'react-router-dom';

const StudentSurveyShow = (props) => {
  const { id } = useParams();
  return (
    <Show {...props} title="New Student Experience Survey">
      <SimpleShowLayout>

        <TextField label="id" source="id" defaultValue={uuidv4()}/>
          <ReferenceField source="studentId" reference="users">
            <TextField source="name" label="Student" />
          </ReferenceField>
          <TextField source="learningStatus" validate={required()} />

          <TextField source="parentAssessment"  />
          <TextField source="studentOrientation"  />
          <ReferenceField source="tutorId" reference="tutors">
            <ReferenceField source="userId" reference="users">
              <TextField source="name" label="Tutor" />
            </ReferenceField>
          </ReferenceField>
          <NumberField source="teacherSatifiedLevel" max="5" min="1" inputMode="numeric" />
          <TextField source="teacherQuality" multiline />
          <TextField source="teacherImprovement" multiline />
          <TextField source="teacherGoodPoint" multiline />

          <NumberField source="programSatifiedLevel" max="5" min="1" inputMode="numeric" />
          <TextField source="programAssessment" multiline />
          <TextField source="programGoodPoint" multiline />
          <TextField source="programImprovement" multiline />

          <NumberField source="platformSatifiedLevel" max="5" min="1" inputMode="numeric" />
          <TextField source="platformAssessment" multiline />
          <TextField source="platformGoodPoint" multiline />
          <TextField source="platformImprovement" multiline />

          <NumberField source="serviceSatifiedLevel" max="5" min="1" inputMode="numeric" />
          <TextField source="serviceAssessment" multiline />
          <TextField source="serviceGoodPoint" multiline />
          <TextField source="serviceImprovement" multiline />

          <TextField source="contribution" multiline  />
          <TextField source="support" multiline required />
      </SimpleShowLayout>
    </Show>
  );
};

export default StudentSurveyShow;
