import { BooleanField, DateField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="date" />
      <TextField source="startTime" />
      <TextField source="endTime" />
      <ReferenceField source="tutorId" reference="users" label="Tutor" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startTimestamp" showTime />
      <DateField source="endTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <BooleanField source="isDeleted" />
    </SimpleShowLayout>
  </Show>
);
