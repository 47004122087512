
import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export default () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField source="userId" reference="users"><TextField source="name" /></ReferenceField>
      <ReferenceField source="topicId" reference="topics"><TextField source="name" /></ReferenceField>
      <ReferenceField source="studentCurriculumId" reference="studentCurriculums"><TextField source="courseId" /></ReferenceField>
      <TextField source="type" />
      <TextField source="status" />
      <DateField source="position" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
