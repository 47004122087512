import { Dialog, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  EditButton,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  useCreate,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';
import authProvider from '../../ReactAdmin/authProvider';
import CurrencyField from '../Custom/CurrencyField';
import { checkActionPermission } from '../../ReactAdmin/utils/util';
import {
  CONTRACT_PAYMENT_STAGES,
  CPStageEnum,
  LCStageEnum,
  LEARNING_CONTRACT_STAGES,
} from '../../ReactAdmin/constants';

const AddContractPayment = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('contractPayments');
  const { data, loading, error } = useGetManyReference('stages', {
    id: 'ContractPayments',
    target: 'resource',
    filter: {
      resource: 'ContractPayments',
      name: CPStageEnum.OPENED,
    },
  });

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (value) => {
    const date = new Date();
    const id = uuidv4();
    const userAuth = JSON.parse(localStorage.getItem('auth'));

    try {
      setLoading(true);
      create(
        'ContractPayments',
        {
          data: {
            id,
            expectedAmount: value.expectedAmount,
            actualAmount: 0,
            totalLessons: value.totalLessons,
            ledgerId: value.ledgerId || null,
            learningContractId: record.id,
            expectedTime: value.expectedTime,
            lastStage: CPStageEnum.OPENED,
          },
        },
        {
          onSuccess: () => {
            create(
              'resourceStages',
              {
                data: {
                  id: uuidv4(),
                  resourceName: 'ContractPayments',
                  resourceId: id,
                  stageId: data[0]?.id,
                  createdBy: userAuth.user.id,
                },
              },
              {
                onSuccess: () => {
                  notify('Create contract payment successfully.', 'success');
                  setShowDialog(false);
                  setLoading(false);
                  refresh();
                },
                onError: ({ err }) => {
                  notify(err.message, 'error');
                  setShowDialog(false);
                  setLoading(false);
                },
              },
            );
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Add Contract Payment
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add Contract Payment"
      >
        <DialogTitle>Add Contract Payment</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <NumberInput source="expectedAmount" validate={required()} fullWidth />
            <NumberInput source="totalLessons" validate={required()} fullWidth />
            <ReferenceInput source="ledgerId" reference="ledgers">
              <SelectInput
                optionText="name"
                allowEmpty
                resettable
                fullWidth
                validate={required()}
              />
            </ReferenceInput>
            <DateTimeInput source="expectedTime" validate={required()} />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const RejectAction = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [update, { isLoading: isCreateLoading }] = useUpdate('learningContracts');
  const [create] = useCreate();

  const { data: rejectedStage } = useGetManyReference('stages', {
    id: 'LearningContracts',
    target: 'resource',
    filter: {
      resource: 'LearningContracts',
      name: 'Rejected',
    },
  });

  const { data: closedStage } = useGetManyReference('stages', {
    id: 'LearningContracts',
    target: 'resource',
    filter: {
      resource: 'LearningContracts',
      name: 'Closed',
    },
  });
  const { data, loading, error } = useGetManyReference('contractPayments', {
    id: record.id,
    target: 'learningContractId',
    filter: {
      learningContractId: record.id,
      lastStage: 'Approved',
    },
  });

  const onReject = async (value) => {
    const date = new Date();
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    try {
      setLoading(true);
      update(
        'LearningContracts',
        {
          id: record.id,
          data: {
            lastStage: LCStageEnum.REJECTED,
            updatedAt: date,
          },
        },
        {
          onSuccess: () => {
            create(
              'resourceStages',
              {
                data: {
                  id: uuidv4(),
                  resourceName: 'LearningContracts',
                  resourceId: record.id,
                  stageId: rejectedStage[0]?.id,
                  createdBy: userAuth.user.id,
                },
              },
              {
                onSuccess: () => {
                  notify('Reject learning contract successfully.', 'success');
                  setShowDialog(false);
                  setLoading(false);
                  refresh();
                },
                onError: ({ err }) => {
                  notify(err.message, 'error');
                  setShowDialog(false);
                  setLoading(false);
                },
              },
            );
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async (value) => {
    const date = new Date();
    const userAuth = JSON.parse(localStorage.getItem('auth'));
    try {
      setLoading(true);
      update(
        'LearningContracts',
        {
          id: record.id,
          data: {
            lastStage: LCStageEnum.CLOSED,
            updatedAt: date,
          },
        },
        {
          onSuccess: () => {
            create(
              'resourceStages',
              {
                data: {
                  id: uuidv4(),
                  resourceName: 'LearningContracts',
                  resourceId: record.id,
                  stageId: closedStage[0]?.id,
                  createdBy: userAuth.user.id,
                },
              },
              {
                onSuccess: () => {
                  notify('Close learning contract successfully.', 'success');
                  setShowDialog(false);
                  setLoading(false);
                  refresh();
                },
                onError: ({ err }) => {
                  notify(err.message, 'error');
                  setShowDialog(false);
                  setLoading(false);
                },
              },
            );
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return record.lastStage !== LCStageEnum.FULLY_PAID && (!data || data?.length <= 0)
    ? record.lastStage !== LCStageEnum.REJECTED && (
        <>
          <Button color="primary" onClick={onReject}>
            Reject
          </Button>
        </>
      )
    : record.lastStage !== LCStageEnum.CLOSED && (
        <>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </>
      );
};

const LearningContractActions = () => {
  const record = useRecordContext();
  const permissions = authProvider.getPermissionsValue();
  return (
    <TopToolbar>
      {record &&
        checkActionPermission(permissions, 'learningContracts', 'addContractPayment') &&
        record.lastStage !== LCStageEnum.FULLY_PAID &&
        record.lastStage !== LCStageEnum.CLOSED &&
        record.lastStage !== LCStageEnum.REJECTED && (
          <>
            <AddContractPayment />
          </>
        )}
      {record && checkActionPermission(permissions, 'learningContracts', 'reject') && (
        <>
          <RejectAction />
        </>
      )}

      {record &&
        checkActionPermission(permissions, 'learningContracts', 'edit') &&
        (record.lastStage === LCStageEnum.PARTIAL_PAID || record.lastStage === LCStageEnum.NEW) && (
          <>
            <EditButton />
          </>
        )}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<LearningContractActions />}>
    <TabbedShowLayout>
      <Tab label="Contract">
        <SimpleShowLayout>
          <ReferenceField source="userId" reference="users" label="Students" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="saleBy" reference="staff" label="Sale By" link="show">
            <TextField source="name" />
          </ReferenceField>
          <CurrencyField source="totalFee" />
          <TextField source="totalLessons" />
          <TextField source="bonusLessons" />
          <CurrencyField source="discount" />
          <SelectField
            source="lastStage"
            choices={LEARNING_CONTRACT_STAGES}
            optionText="name"
            optionValue="id"
          />
          <TextField source="type" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
      </Tab>
      <Tab label="Payments">
        <ReferenceManyField
          reference="contractPayments"
          target="learningContractId"
          addLabel={false}
          sort={{ field: 'updatedAt', order: 'DESC' }}

        >
          <Datagrid rowClick={(id, basePath, record) => `/contractPayments/${record.id}/show`}>
            <ReferenceField
              source="learningContractId"
              reference="learningContracts"
              label="Student"
            >
              <ReferenceField source="userId" reference="users" link="show">
                <TextField source="name" />
              </ReferenceField>
            </ReferenceField>
            <CurrencyField source="expectedAmount" />
            <CurrencyField source="actualAmount" />
            <NumberField source="totalLessons" />
            <ReferenceField source="ledgerId" reference="ledgers">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="expectedTime" showTime />
            <DateField source="actualTime" showTime />

            <SelectField
              alwaysOn
              source="lastStage"
              choices={CONTRACT_PAYMENT_STAGES}
              optionText="name"
              optionValue="id"
            />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Stage history">
        <ReferenceManyField
          reference="resourceStages"
          target="resourceId"
          sort={{ field: 'updatedAt', order: 'DESC' }}
        >
          <Datagrid rowClick="show">
            <ReferenceField reference="stage" source="stageId" label="Stage">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference="users" source="createdBy" label="Created By">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
