import {
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

export default () => (
  <Edit>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="idPrefix" />
      <TextInput source="name" />
      <TextInput source="layout" />
      <TextInput source="image" fullWidth />
    </SimpleForm>
  </Edit>
);
