import {
  SelectInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  DateInput,
  useCreate,
} from 'react-admin';
import hash36 from 'hash36';

export default () => {
  const [create] = useCreate();
  const voucherSave = (data) => {
    create('vouchers', {
      data: {
        ...data,
        // eslint-disable-next-line new-cap
        code: hash36
          .sha256(`6WX8B100GJ${JSON.stringify(data)}`)
          .substring(0, 8)
          .toUpperCase(),
      },
    });
  };
  const transform = (data) => ({
    ...data,
    // eslint-disable-next-line new-cap
    code: hash36
      .sha256(`6WX8B100GJ${JSON.stringify(data)}`)
      .substring(0, 8)
      .toUpperCase(),
  });
  return (
    <Create redirect="show" transform={transform}>
      <SimpleForm>
        <ReferenceInput source="voucherTypeId" reference="voucherTypes">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="parentName" />
        <TextInput source="studentName" />
        <TextInput source="phone" />
        <DateInput source="expirationDate" />
      </SimpleForm>
    </Create>
  );
};
