export const generatePassword = (length) => {
  // const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const letters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  return Array(length)
    .fill('')
    .map((x) => letters[Math.floor(Math.random() * letters.length)])
    .join('');
};

export const dummy = 'dummy';
