import { AutocompleteInput, BooleanField, BooleanInput, DateTimeInput, Edit, NullableBooleanInput, ReferenceField, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="date" />
      <TextInput source="startTime" />
      <TextInput source="endTime" />
      <ReferenceInput source="tutorId" reference="users" alwaysOn perPage={10000}>
        <AutocompleteInput optionText="name" allowEmpty resettable />
      </ReferenceInput>
      <DateTimeInput source="startTimestamp" />
      <DateTimeInput source="endTimestamp" />
      <BooleanInput source="isDeleted" />
    </SimpleForm>
  </Edit>
);
