import {
  Confirm,
  EditButton,
  Show,
  TopToolbar,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  useRecordContext,
  WithRecord,
  TabbedShowLayout,
  Tab,
  Labeled,
  NumberField,
  useGetManyReference,
  useNotify,
  useUpdate,
  RecordContextProvider,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  SelectInput,
  TextInput,
  SaveButton,
  ReferenceManyField,
  Datagrid,
} from 'react-admin';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Dialog, DialogTitle, Grid, Link, Pagination, Rating } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Config from '../../Config/index';
import LinkField from '../Custom/LinkField';
import ExistBooleanField from '../Custom/ExistBooleanField';

const axios = require('axios');

const InlineLabelValueField = ({ label, source, record }) => (
  <div>
    <span style={{ fontWeight: '600', fontSize: '14px' }}>{label}: </span>
    <span style={{ marginLeft: '10px', fontSize: '14px' }}>{record[source]}</span>
  </div>
);

const InlineLabelRatingField = ({ label, source, record }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
    <span style={{ fontWeight: 'normal', fontSize: '14px', marginRight: '15px' }}>{label}: </span>
    <Rating value={record[source] || 0} name={source} readOnly />
  </div>
);

function cancelBooking(bookingId) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/booking/cancel`,
      { bookingId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

const CancelBookingButton = ({ bookingId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Cancel booking
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to cancel this booking?"
        onConfirm={() => {
          cancelBooking(bookingId);
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const UpdateLessonStatus = ({ id }) => {
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [update, { isLoading: isCreateLoading }] = useUpdate('classReviews');

  const updateLessonStatus = (value) => {
    setLoading(true);
    setShowDialog(false);
    try {
      update(
        'classReviews',
        {
          id,
          data: {
            lessonStatusId: value.lessonStatusId,
          },
        },
        {
          onSuccess: () => {
            notify('Update class review successfully.', 'success');
            setShowDialog(false);
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
          },
        },
      );
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button color="primary" onClick={() => setShowDialog(true)}>
        Update Lesson Status
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update Lesson Status"
      >
        <DialogTitle>Update Lesson Status </DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={updateLessonStatus}
            toolbar={
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <CancelIcon />
                </Button>
                <SaveButton disabled={isLoading} />
              </Toolbar>
            }
          >
            <ReferenceInput source="lessonStatusId" reference="classReviewStatuses">
              <SelectInput optionText="status" optionValue="id" fullWidth />
            </ReferenceInput>
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

const BookingShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      {record && (
        <>
          <EditButton />
          <CancelBookingButton bookingId={record.id} />
        </>
      )}
    </TopToolbar>
  );
};

const ClassReviewTab = () => {
  const record = useRecordContext();
  const { data, loading, error } = useGetManyReference('classReviews', {
    target: 'bookingId',
    id: record.id,
    filter: {
      bookingId: record.id,
    },
  });
  if (!data || data.length === 0) return null;

  return (
    <>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 2fr)', width: 1 }}>
        <UpdateLessonStatus id={data[0].id} />
      </Box>

      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <Labeled label="Book Name">
          <TextField source="book" record={data[0]} />
        </Labeled>
        <Labeled label="Lesson Status">
          <ReferenceField record={data[0]} reference="classReviewStatuses" source="lessonStatusId">
            <TextField source="status" />
          </ReferenceField>
        </Labeled>
        <Labeled label="Unit">
          <NumberField source="unit" record={data[0]} />
        </Labeled>

        <Labeled label="Lesson">
          <TextField source="lesson" record={data[0]} />
        </Labeled>
        <Labeled label="Lesson Progress">
          <TextField source="lessonProgress" record={data[0]} />
        </Labeled>
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <Labeled label="Behavioir Rating">
          <NumberField source="behaviorRating" record={data[0]} />
        </Labeled>
        <Labeled label="Behavioir Comment">
          <TextField source="behaviorComment" record={data[0]} />
        </Labeled>
        <Labeled label="Listening Rating">
          <NumberField source="listeningRating" record={data[0]} />
        </Labeled>
        <Labeled label="Listening Comment">
          <TextField source="listeningComment" record={data[0]} />
        </Labeled>
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <Labeled label="Speaking Rating">
          <NumberField source="speakingRating" record={data[0]} />
        </Labeled>
        <Labeled label="Speaking Comment">
          <TextField source="speakingComment" record={data[0]} />
        </Labeled>
        <Labeled label="Vocabulary Rating">
          <NumberField source="listeningRating" record={data[0]} />
        </Labeled>

        <Labeled label="Vocabulary Comment">
          <TextField source="vocabularyComment" record={data[0]} />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="Homework Comment">
          <TextField source="homeworkComment" record={data[0]} />
        </Labeled>
      </Box>
    </>
  );
};

const TrialBookingReviewTab = () => {
  const record = useRecordContext();
  const { data, loading, error } = useGetManyReference('trialBookingReviews', {
    target: 'bookingId',
    id: record.id,
    filter: {
      bookingId: record.id,
    },
  });
  if (!data || data.length === 0) return null;

  return (
    <>
      <InlineLabelValueField label="Student Level" source="studentLevel" record={data[0]} />

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '20px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '15px' }}> Fluency & Coherence: </span>
        <InlineLabelRatingField
          label="Avoid silence or hesitation"
          source="fcSpeakTopicWithEnglishMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Speak at length on each topic & Use English only"
          source="fcAvoidSilenceOrHesitationMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Use correct words to express ideas"
          source="fcUseCorrectWordsMark"
          record={data[0]}
        />
        <InlineLabelValueField
          label="Fluency & Coherence Comment"
          source="fluencyAndCoherenceComment"
          record={data[0]}
        />
      </Box>
      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '30px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '15px' }}> Lexical Resources: </span>
        <InlineLabelRatingField
          label="Use a wide range of vocabulary"
          source="lrWideRangeOfVocabularyMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Paraphrase/ Speak in full sentences"
          source="lrParaphraseAndSpeakMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Use phrasal verbs and collocation/ Use the synonym or paraphrase"
          source="lrSynonymOrParaphraseMark"
          record={data[0]}
        />
        <InlineLabelValueField
          label="Lexical Resources Comment"
          source="lexicalResourcesComment"
          record={data[0]}
        />
      </Box>

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '30px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '15px' }}>
          {' '}
          Grammatical Range & Accuracy:{' '}
        </span>
        <InlineLabelRatingField
          label="Speak in complex sentences"
          source="graSpeakInComplexSentencesMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Use a variety of grammatical forms/ Use correct tenses with correct forms of verbs"
          source="graUseCorrectTensesMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Avoid grammatical mistakes"
          source="graAvoidGrammaticalMistakesMark"
          record={data[0]}
        />
        <InlineLabelValueField
          label="Grammatical Range & Accuracy Comment"
          source="graComment"
          record={data[0]}
        />
      </Box>

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '30px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '15px' }}> Pronunciation: </span>
        <InlineLabelRatingField
          label="Pronounce words accuratelv/ Pronounce clearl"
          source="proPronounceWordsAccuratelyMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Join sounds together/ Pronounce with ending sounds, stress & intonation:"
          source="proJoinSoundsTogetherMark"
          record={data[0]}
        />
        <InlineLabelRatingField
          label="Vary intonation"
          source="proVaryIntonationMark"
          record={data[0]}
        />
        <InlineLabelValueField
          label="Pronunciation Comment"
          source="pronunciationComment"
          record={data[0]}
        />
      </Box>

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '20px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>
          Personal attitude towards English learning:{' '}
        </span>
        <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
          {' '}
          {data[0]?.personalAttitude || 'N/A'}{' '}
        </span>
      </Box>

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '10px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>
          Second Language Acquisition:{' '}
        </span>
        <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
          {' '}
          {data[0]?.secondLanguageAcquisition || 'N/A'}{' '}
        </span>
      </Box>
      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '10px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>Strengths/ Weakness: </span>
        <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
          {' '}
          {data[0]?.strengthAndWeakness || 'N/A'}{' '}
        </span>
      </Box>

      <Box
        sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}
        style={{ marginTop: '10px', marginBottom: '20px' }}
      >
        <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>
          Recommendation (suggest a book):{' '}
        </span>
        <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
          {' '}
          {data[0]?.recommendation || 'N/A'}{' '}
        </span>


      </Box>
      <Labeled label="Lesson Status">
          <ReferenceField record={data[0]} reference="classReviewStatuses" source="lessonStatusId">
            <TextField source="status" />
          </ReferenceField>
        </Labeled>
      <Labeled label="Created At">
          <DateField source="createdAt" showTime record={data[0]} />
        </Labeled>
        <Labeled label="Updated At">
          <DateField source="updatedAt" showTime record={data[0]} />
        </Labeled>
    </>
  );
};

const BookingShow = (props) => {
  const { id } = props;

  return (
    <Show actions={<BookingShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Booking Detail">
          <ReferenceField source="userId" reference="users" link="show" >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="scheduleDetailId" reference="scheduleDetails">
            <TextField source="id" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <BooleanField source="isDeleted" />
          <BooleanField source="isTrial" />
          <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
          <LinkField
            source="studentMeetingLink"
            prefix={Config.appUrl}
            text="Student lesson link"
          />
          <LinkField source="googleMeetLink" text="Google meet link" />
          <WithRecord
            label="Trial instruction"
            render={(record) =>
              record &&
              record.isTrial &&
              record.googleMeetLink && (
                <Link
                  href={`${process.env.REACT_APP_APP_URL}/trial-instruction/${record.id}`}
                  target="_blank"
                >
                  Trail instruction
                </Link>
              )
            }
          />
          <TextField source="studentRequest" component="pre" />
          <TextField source="status" />
          <TextField source="recordUrl" />
          <NumberField source="convertedLesson" />
        </Tab>
        <Tab label="Class Review">
          <ClassReviewTab />
        </Tab>
        <Tab label="Attendances">
          <ReferenceManyField
            reference="bookingAttendances"
            target="bookingId"
            addLabel={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid>
              <TextField source="id" />
              <ReferenceField source="userId" reference="users" label="User">
                <TextField source="name" />
              </ReferenceField>
              <ExistBooleanField source="isJoin" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
              <TextField source="socketId" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Trial Booking Review">
          <TrialBookingReviewTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BookingShow;
