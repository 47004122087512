import React from 'react';
import { Datagrid, DateField, List, ReferenceField, SelectInput, TextField } from 'react-admin';

const Filter = [
  <SelectInput
    alwaysOn
    source="status"
    choices={[
      { id: 'NEW', name: 'NEW' },
      { id: 'APPROVE', name: 'APPROVE' },
      { id: 'REJECT', name: 'REJECT' },
    ]}
  />,
];

export default (props) => (
  <List filters={Filter} {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <ReferenceField source="walletId" reference="wallets" label="users" >
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="price" />
      <TextField source="venue" />
      <ReferenceField source="ledgerId" reference="ledgers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="saleByStaffId" reference="staffs">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="saleAt" />
      <TextField source="status" />
      <TextField source="dealType" />
      <TextField source="note" />
      <TextField source="reason" />
      <ReferenceField source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
