import React from 'react';
import { BooleanField, Datagrid, DateField, List, ReferenceField, SelectInput, TextField } from 'react-admin';


export default (props) => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="date" />
      <TextField source="startTime" />
      <TextField source="endTime" />
      <ReferenceField source="tutorId" reference="users" label="Tutor" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startTimestamp" showTime />
      <DateField source="endTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="isDeleted" />
    </Datagrid>
  </List>
);
