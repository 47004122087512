import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';

export default (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick={(id, basePath, record) => `/talents/${record.id}/show`}>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="fullName" />
      <TextField source="name" />
      <TextField source="phone" />
      <NumberField source="birthYear" />
      <TextField source="address" />
      <NumberField source="desiredSalary" />
      <BooleanField source="ownLaptop" />
      <TextField source="experience" />
      <DateField source="createdAt"  showTime/>
      <DateField source="updatedAt" showTime/>
    </Datagrid>
  </List>
);
