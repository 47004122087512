import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  ImageField,
  Show,
  SimpleShowLayout,
} from 'react-admin';

export default () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="layout" />
      <ImageField source="image" />
      <TextField source="idPrefix" />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
);
