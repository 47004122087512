import React from 'react';
import {
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { Box } from '@mui/material';
import { CONTRACT_PAYMENT_STAGES } from '../../ReactAdmin/constants';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <NumberInput source="expectedAmount" validate={required()} />
        <NumberInput source="actualAmount" validate={required()} />
        <NumberInput source="totalLessons" validate={required()} />
        <ReferenceInput source="ledgerId" reference="ledgers">
          <SelectInput optionText="name" allowEmpty resettable fullWidth validate={required()} />
        </ReferenceInput>
        <DateTimeInput source="expectedTime" validate={required()} />
        <DateTimeInput source="actualTime" validate={required()} />
        <SelectInput
          alwaysOn
          source="lastStage"
          choices={CONTRACT_PAYMENT_STAGES}
          optionText="name"
          optionValue="id"
        />
      </Box>
    </SimpleForm>
  </Edit>
);
