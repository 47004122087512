import React, { useState } from 'react';
import {
  Button,
  useRecordContext,
  Form,
  SaveButton,
  RecordContextProvider,
  TextInput,
  SimpleForm,
  AutocompleteInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  Toolbar,
  useNotify,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import bookingService from './Business/bookingService';

const MassCancelBookingButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };
  const handleSubmit = async ({ startPeriod, cancelNote }) => {
    try {
      setLoading(true);
      await bookingService.massCancel(record.id, startPeriod.getTime(), cancelNote);
      notify(`Booking after ${startPeriod} has been cancelled`, { type: 'success' });
      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const MassCancelToolbar = () => (
    <Toolbar>
      <SaveButton label="Mass Cancel Bookings" disabled={isLoading} />
    </Toolbar>
  );

  const validateMassBooking = ({ startPeriod }) => {
    const errors = {};
    if (!startPeriod || startPeriod < Date.now() + 2 * 60 * 60 * 1000)
      errors.startPeriod = 'Must not cancel booking having start time before 2 hours from now';
    return errors;
  };

  return (
    <>
      <Button onClick={handleClick}>Mass cancel Booking</Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Mass cancel Booking"
      >
        <DialogTitle>Mass cancel Booking</DialogTitle>
        <RecordContextProvider>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={<MassCancelToolbar />}
            validate={validateMassBooking}
          >
            <DateTimeInput source="startPeriod" />
            <TextInput source="cancelNote" />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default MassCancelBookingButton;
