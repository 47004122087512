import {
  NullableBooleanInput,
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
} from 'react-admin';

const courseListFilters = [
  <NullableBooleanInput source='visible' alwaysOn/>,
];

export default (props) => (
  <List {...props} filters={courseListFilters} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick='show'>
      <TextField source='name' />
      <TextField source='level' />
      <BooleanField source='visible' />
      <NumberField source='displayOrder' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />

    </Datagrid>
  </List>
);
