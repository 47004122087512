import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  RecordContextProvider,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';

import permissionService from './Business/permissionService';

const ClonePermissionButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const { data: staffs } = await dataProvider.getList('Staff', {
          pagination: { page: 1, perPage: 1000000 },
        });

        const userIds = staffs.map((staff) => staff.userId);

        const { data: usersData } = await dataProvider.getList('Users', {
          filter: { id: Array.from(new Set(userIds)) },
          pagination: { page: 1, perPage: 1000000 },
        });

        const options = usersData.map((user) => ({
          id: user.id,
          email: user.email,
        }));
        setUserOptions(options);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUserOptions();
  }, [dataProvider]);

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await permissionService.cloneUserPermission(record.id, value.userId);
      notify(
        `Clone permisson from ${userOptions.find((val) => val.id === value.userId)?.email} successfully !`,
        'success',
      );
      setShowDialog(false);
      setLoading(false);
      refresh();
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
      setShowDialog(false);
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar style={{ justifyContent: 'flex-end' }}>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Clone Permission</Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Note">
        <DialogTitle>Clone Permission</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <SelectInput
              source="userId"
              choices={userOptions}
              optionText="email"
              optionValue="id"
              fullWidth
            />
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default ClonePermissionButton;
