import HttpClient from '../../../HttpClient/index';

const permissionRepository = {};

permissionRepository.clonePermission = (toUserId, fromUserId) =>
  HttpClient.post('/user/clone-permission', {
    toUserId,
    fromUserId
  });

export default permissionRepository;
