import {
  DateField,
  List,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="courseId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <NumberField source="orderCourse" />
      <TextField source="videoUrl" />
      <TextField source="nameFile" />
      <TextField source="type" />
      <TextField source="numberOfPages" />
      <DateField source="createdAt" />
      <RichTextField source="beforeTheClassNotes" />
      <RichTextField source="afterTheClassNotes" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
