
export const LCStageEnum  ={
    NEW : 'NEW',
    FULLY_PAID :'FULLY_PAID',
    PARTIAL_PAID : 'PARTIAL_PAID',
    REJECTED: 'REJECTED',
    CLOSED: 'CLOSED'
}

export const CPStageEnum  ={
    OPENED : 'Opened',
    PAID :'Paid',
    APPROVED : 'Approved',
    CANCELLED: 'Cancelled'
}

export const LEARNING_CONTRACT_STAGES = [
  { id: LCStageEnum.NEW, name: 'New' },
  { id: LCStageEnum.FULLY_PAID, name: 'Fully Paid' },
  { id: LCStageEnum.PARTIAL_PAID, name: 'Partial Paid' },
  { id: LCStageEnum.REJECTED, name: 'Rejected' },
  { id: LCStageEnum.CLOSED, name: 'Closed' },
];

export const CONTRACT_PAYMENT_STAGES = [
  { id: CPStageEnum.OPENED , name: 'Opened' },
  { id: CPStageEnum.PAID , name: 'Paid' },
  { id: CPStageEnum.APPROVED , name: 'Approved' },
  { id: CPStageEnum.CANCELLED , name: 'Cancelled' },
];

export const STUDENT_LEVEL = [
  {
    id: 'BEGINNER',
    name: 'Pre A1 (Beginner)',
  },
  {
    id: 'HIGHER_BEGINNER',
    name: 'A1.1 (Higher Beginner 1)',
  },
  {
    id: 'HIGHER_BEGINNER_2',
    name: 'A1.2 (Higher Beginner 2)',
  },
  {
    id: 'HIGHER_BEGINNER_3',
    name: 'A1.3 (Higher Beginner 3)',
  },
  {
    id: 'HIGHER_BEGINNER_4',
    name: 'A1.4 (Higher Beginner 4)',
  },
  {
    id: 'PRE_INTERMEDIATE',
    name: 'A2.1 (Pre-Intermediate 1)',
  },
  {
    id: 'PRE_INTERMEDIATE_2',
    name: 'A2.2 (Pre-Intermediate 2)',
  },
  {
    id: 'PRE_INTERMEDIATE_3',
    name: 'A2.3 (Pre-Intermediate 3)',
  },
  {
    id: 'PRE_INTERMEDIATE_4',
    name: 'A2.4 (Pre-Intermediate 4)',
  },
  {
    id: 'INTERMEDIATE',
    name: 'B1.1 (Intermediate 1)',
  },
  {
    id: 'INTERMEDIATE_2',
    name: 'B1.2 (Intermediate 2)',
  },
  {
    id: 'INTERMEDIATE_3',
    name: 'B1.3 (Intermediate 3)',
  },
  {
    id: 'INTERMEDIATE_4',
    name: 'B1.4 (Intermediate 4)',
    groupId: 4,
  },
  {
    id: 'UPPER_INTERMEDIATE',
    name: 'B2.1 (Upper-Intermediate 1)',
    groupId: 5,
  },
  {
    id: 'UPPER_INTERMEDIATE_2',
    name: 'B2.2 (Upper-Intermediate 2)',
    groupId: 5,
  },
  {
    id: 'UPPER_INTERMEDIATE_3',
    name: 'B2.3 (Upper-Intermediate 3)',
  },
  {
    id: 'UPPER_INTERMEDIATE_4',
    name: 'B2.4 (Upper-Intermediate 4)',
  },
  {
    id: 'ADVANCED',
    name: 'C1.1 (Advanced 1)',
  },
  {
    id: 'ADVANCED_2',
    name: 'C1.2 (Advanced 2)',
  },
  {
    id: 'ADVANCED_3',
    name: 'C1.3 (Advanced 3)',
  },
  {
    id: 'ADVANCED_4',
    name: 'C1.4 (Advanced 4)',
  },
  {
    id: 'PROFICIENCY',
    name: 'C2.1 (Proficiency 1)',
  },
  {
    id: 'PROFICIENCY_2',
    name: 'C2.2 (Proficiency 2)',
  },
  {
    id: 'PROFICIENCY_3',
    name: 'C2.3 (Proficiency 3)',
  },
  {
    id: 'PROFICIENCY_4',
    name: 'C2.4 (Proficiency 4)',
  },
];