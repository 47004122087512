import {
  AutocompleteInput,
  DateInput,
  Edit,
  FieldTitle,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import Box from '@mui/material/Grid';
import React from 'react';

const transformRecord = (record) => ({
  ...record,
  ledgerId: record.ledgerId || null,
  saleByStaffId: record.saleByStaffId || null,
});

export default (props) => (
  <Edit {...props} transform={transformRecord} redirect="show">
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(4, 1fr)', width: 1 }}>
        <NumberInput source="price" />
        <NumberInput source="revenue" />
        <TextInput source="type" />
        <ReferenceInput source="ledgerId" reference="ledgers">
          <SelectInput optionText="name" allowEmpty resettable />
        </ReferenceInput>
        <ReferenceInput source="saleByStaffId" reference="staffs" perPage={5000}>
          <AutocompleteInput optionText="name" allowEmpty resettable />
        </ReferenceInput>
        <DateInput source="saleAt" defaultValue={new Date().toISOString().split('T')[0]} />
        <SelectInput
          source="dealType"
          allowEmpty
          choices={[{ name: 'New' }, { name: 'Renew' }]}
          optionValue="name"
        />
      </Box>
      <TextInput source="note" fullWidth/>
    </SimpleForm>
  </Edit>
);
