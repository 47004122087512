import { Box } from '@mui/material';
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

export default (props) => (
  <Edit {...props} redirect="show">
    <SimpleForm redirect="show">
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <BooleanInput source="isDeleted" />
        <BooleanInput source="isTrial" />
        <TextInput source="tutorMeetingLink" fullWidth />
        <TextInput source="studentMeetingLink" fullWidth />
        <TextInput source="googleMeetLink" fullWidth />
        <TextInput source="studentRequest" fullWidth multiline />
        <NumberInput source="convertedLesson" fullWidth />
      </Box>
    </SimpleForm>
  </Edit>
);
