import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';
import LinkField from '../Custom/LinkField';
import Config from '../../Config';

const BookingViewFilters = [
  <TextInput source="name"  alwaysOn/>,
  <TextInput source="tutorName" alwaysOn/>,
  <DateInput source="startPeriodDate" label="Start" alwaysOn   />,
  <BooleanInput source='isDeleted' alwaysOn/>
];

export default (props) => (
  <List
    {...props}
    sort={{ field: 'startPeriodTimestamp', order: 'DESC' }}
    filters={BookingViewFilters}
  >
    <Datagrid rowClick={(id, basePath, record) => `/bookings/${record.id}/show`}>
      <ReferenceField source="userId" reference="users" label="Student" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="tutorId" reference="users" label="Tutor" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startPeriodTimestamp" showTime locales={Intl.DateTimeFormat} />
      <DateField source="startPeriodDate" />
      <DateField source="endPeriodTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <BooleanField source="isDeleted" />
      <BooleanField source="isTrial" />
      <TextField source="studentRequest" />
      <TextField source="status" />
      <TextField source="tutorReview" />
      <LinkField source="studentMeetingLink" prefix={Config.appUrl} text="Student lesson link" />
      <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
      <LinkField source="googleMeetLink" text="Google meet link" />
    </Datagrid>
  </List>
);
