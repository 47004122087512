import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Button,
  RecordContextProvider,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';

const CreateUserRoleButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate('userRoles');

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async (value) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    try {
      setLoading(true);
      create(
        'userRoles',
        {
          data: {
            id: uuidv4(),
            roleId: value.roleId,
            userId :record.id,
          },
        },
        {
          onSuccess: () => {
            notify('Add roles successfully.', 'success');
            setShowDialog(false);
            setLoading(false);
            refresh();
          },
          onError: ({ err }) => {
            notify(err.message, 'error');
            setShowDialog(false);
            setLoading(false);
          },
        },
      );

      setShowDialog(false);
    } catch (e) {
      notify(e?.response?.data?.errorMessage, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  const SaveToolbar = () => (
    <Toolbar style={{ justifyContent: 'flex-end' }}>
      <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
        <CancelIcon />
      </Button>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );

  return (
    <>
      <Button onClick={handleClick}>Add Role</Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Role">
        <DialogTitle>Add Role</DialogTitle>
        <RecordContextProvider>
          <SimpleForm onSubmit={handleSubmit} toolbar={<SaveToolbar />}>
            <ReferenceInput source="roleId" reference="Roles" alwaysOn perPage={10000}>
              <AutocompleteInput
                source="roleId"
                validate={required()}
                fullWidth
                multiline
                inputProps={{ maxLength: 10240 }}
              />
            </ReferenceInput>
          </SimpleForm>
        </RecordContextProvider>
      </Dialog>
    </>
  );
};

export default CreateUserRoleButton;
