import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
  AutocompleteInput,
  NumberInput, useDataProvider,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Grid';

export default (props) => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  useEffect(async () => {
    const { data } = await dataProvider.getList('Users', {
      pagination: { page: 1, perPage: 1000000 },
    });
    setUsers(data.filter(user => user.email).map(user => ({id: user.id, email: user.email})));
  }, []);
  return (
  <Edit {...props}>
    <SimpleForm>
      <AutocompleteInput source="userId" optionText="email" choices={users} isLoading={users === []} optionValue="id" sx={{ minWidth: 300 }}/>
      <TextInput source="callerNumber" />
      <TextInput source="name" />
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(4, 1fr)', width: 1 }}>
        <NumberInput source="kpiCall" />
        <NumberInput source="kpiTrial" />
        <NumberInput source="kpiNcc" />
        <NumberInput source="kpiStudents" />
      </Box>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)', width: 1 }}>
        <TextInput source="zohoAccount" />
        <TextInput source="zohoTagId" />
      </Box>
      <Box>
        <DateInput source="startWorkingDay" />
        <DateInput source="endWorkingDay" />
      </Box>
      <TextInput source="role" />
      <BooleanInput source="visible" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
)};
