import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';
import { LEARNING_CONTRACT_STAGES } from '../../ReactAdmin/constants';

const Filter = [
  <SelectInput
    alwaysOn
    source="lastStage"
    choices={LEARNING_CONTRACT_STAGES}
  />,
];
export default (props) => (
  <List filters={Filter} {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick={(id, basePath, record) => `/learningContracts/${record.id}/show`}>
      <TextField source="id" />

      <ReferenceField source="userId" reference="users" label="Student" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="saleBy" reference="staff" label="Sale By" link="show">
        <TextField source="name" />
      </ReferenceField>
      <CurrencyField source="totalFee" />
      <TextField source="totalLessons" />
      <TextField source="bonusLessons" />
      <CurrencyField source="discount" />
      <SelectField
        source="lastStage"
        choices={LEARNING_CONTRACT_STAGES}
        optionText="name"
        optionValue="id"
      />
      <TextField source="type" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
