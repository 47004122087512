import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { Box } from '@mui/material';

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(1, 1fr)', width: 1 }}>
        <TextInput source="id" defaultValue={uuidv4()} disabled />
        <TextInput source="name" />
        <TextInput source="imageUrl" />
        <TextInput source="level" min={0} />
        <NumberInput source="defaultPrice" />
        <NumberInput source="coursePrice" />
        <TextInput source="reason" multiline minRows={3} />
        <TextInput source="purpose" multiline minRows={3} />
        <TextInput source="courseType" />
        <TextInput source="sectionType" />
        <NumberInput source="displayOrder" />
        <BooleanInput source="visible" />
      </Box>
    </SimpleForm>
  </Create>
);
