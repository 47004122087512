import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useDataProvider,
} from 'react-admin';
import React, { useEffect, useState } from 'react';

export default () => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  useEffect(async () => {
    const { data } = await dataProvider.getList('Staff', {
      filter: { active: true },
      pagination: { page: 1, perPage: 1000000 },
    });
    setUsers(data);
  }, []);
  return (
    <Create>
      <SimpleForm>
        <AutocompleteInput
          source="userId"
          optionText="name"
          choices={users}
          isLoading={users === []}
          optionValue="userId"
          sx={{ minWidth: 300 }}
        />
        <TextInput source="resource" />
        <TextInput source="action" />
      </SimpleForm>
    </Create>
  );
};
