import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/react-hooks';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error/index';
import Config from '../Config/index';

const httpLink = createHttpLink({
  uri: `${Config.apiUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const auth = JSON.parse(localStorage.getItem('auth'));
  // return the headers to the context so httpLink can read them
  // if (auth)
  return {
    headers: {
      ...headers,
      authorization: auth ? `Bearer ${auth.accessToken}` : '',
    },
  };
  // return {
  //   headers,
  // };
});

export default new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if (networkError.statusCode === 401)
          localStorage.removeItem('auth');
          window.location.href = '/#/login'
          return;
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
    }),
    authLink.concat(httpLink),
  ]),
  cache: new InMemoryCache(),
});
