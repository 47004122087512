import React from 'react';
import {
  Button,
  Confirm,
  DateField,
  ReferenceField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';
import Config from '../../Config';
import authProvider from '../../ReactAdmin/authProvider';

const axios = require('axios');

const ApproveButton = ({ depositReqId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const notify = useNotify();

  const approve = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    axios
      .post(
        `${Config.apiUrl}/wallet/approve-deposit`,
        { depositReqId },
        { headers: { Authorization: `Bearer ${auth.accessToken}` } },
      )
      .then((response) => {
        notify('Successfully approve deposit');
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Approve
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to approve this deposit request?"
        onConfirm={() => {
          setIsOpen(false);
          approve();
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const RejectButton = ({ depositReqId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const notify = useNotify();

  const reject = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    axios
      .post(
        `${Config.apiUrl}/wallet/reject-deposit`,
        {  depositReqId },
        { headers: { Authorization: `Bearer ${auth.accessToken}` } },
      )
      .then((response) => {
        notify('Successfully reject deposit.');
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Reject
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to reject this deposit request?"
        onConfirm={() => {
          reject();
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};

const DepositRequestShowActions = () => {
  const record = useRecordContext();
  const role = authProvider.getRole();
  return (
    <TopToolbar>
      {record && record.status === 'NEW' && (
        <>
          <ApproveButton depositReqId={record.id} />
          <RejectButton depositReqId={record.id} />
        </>
      )}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<DepositRequestShowActions />}>
    <SimpleShowLayout>
      <ReferenceField source="walletId" reference="wallets" label="User" link="show">
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="price" />
      <TextField source="venue" />
      <ReferenceField source="ledgerId" reference="ledgers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="saleByStaffId" reference="staffs">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="saleAt" />
      <TextField source="status" />
      <TextField source="dealType" />
      <TextField source="note" />
      <TextField source="reason" />
      <ReferenceField source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
