import React, { useState } from 'react';
import {
  Button,
  Form,
  ReferenceInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
} from 'react-admin';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import Config from '../../Config/index';
import { generatePassword } from '../../Utils/index';

const axios = require('axios');

const RegisterStudent = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const handleRegisterStudentClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleSubmit = async (values) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    setLoading(true);
    axios
      .post(`${Config.apiUrl}/student/register`, values, {
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      })
      .then((response) => {
        notify(`Successfully register ${values.email}`);
        setLoading(false);
        setShowDialog(false);
      })
      .catch((error) => {
        notify(`Error: ${error}`);
        setLoading(false);
        setShowDialog(false);
        console.log(error);
      });
  };

  return (
    <>
      <Button onClick={handleRegisterStudentClick} label="Register" />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Deposit">
        <DialogTitle>Register Student</DialogTitle>

        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput
              source="email"
              validate={regex(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                'Invalid email',
              )}
              fullWidth
            />
            <TextInput
              source="password"
              fullWidth
              defaultValue={generatePassword(8)}
              validate={[
                required(),
                regex(/^.{6,128}$/, 'Password must have at least 6 characters'),
              ]}
            />
            <TextInput source="name" validate={required()} fullWidth />
            <TextInput
              source="phone"
              validate={regex(/^84\d{9}$/, 'Phone number must begin with 84')}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
              <CancelIcon />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default RegisterStudent;
